import React from 'react';
import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';

import { Home } from './pages/Home.jsx';
import { LoginLayout, LoginAuthOk, loginAuthOkLoader, LogoutLayout } from './pages/Auth.jsx';
import { MyProfile, myProfileLoader } from './pages/MyProfile.jsx';
import { MyRequests, myRequestsLoader } from './pages/MyChangeRequests.jsx';
import { FortnoxLayout, fortnoxLoader } from './pages/Fortnox.jsx';
import { AnalyticsLayout, analyticsLoader } from './pages/Analytics.jsx';
import { BalanceAccount, balanceAccountLoader } from './pages/BalanceAccount.jsx';
import { ExpenseReports, expenseReportsLoader } from './pages/ExpenseReports.jsx';
import { Employees, employeesLoader } from './pages/Employees.jsx';
import { CostCenters, costCentersLoader } from './pages/CostCenters.jsx';
import { CostCenter } from './pages/CostCenter.jsx';
import { HealthcheckTransactions, healthcheckTransactionsLoader } from './pages/HealthcheckTransactions.jsx';
import { Shop, shopLoader } from './pages/Shop.jsx';
import { Orders, ordersLoader } from './pages/ShopOrders.jsx';
import { AddArticle } from './pages/ShopAddArticle.jsx';
import { ArticleList, articleListLoader } from './pages/ShopArticleList.jsx';
import Footer from './components/Footer.jsx';
import { ProtectedRoute } from './components/ProtectedRoute.jsx';
import { ROLES } from './utils/rbac.js';
import { Unauthorized } from './pages/Unauthorized.jsx';
import { MyOrders, myOrdersLoader } from './pages/MyShopOrders.jsx';
import { RequestsList, requestsListLoader } from './pages/ChangeRequestsList.jsx';
import { SalaryCalculation, salaryCalculationLoader } from './pages/SalaryCalculation.jsx';
import './style.scss';
import Sidebar from './components/Sidebar.jsx';
import { ErrorBoundary } from './components/ErrorBoundary.jsx';
import { TimeReports, timeReportsLoader } from './pages/TimeReports.jsx';
import { Events, eventsLoader } from './pages/Events.jsx';
import { CreateEvent, createEventLoader } from './pages/CreateEvent.jsx';
import { EventManagement, eventManagementLoader } from './pages/EventManagement.jsx';

const Layout = () => {
  return (
    <div className="app">
      <Sidebar />
      <div className="main-content">
        <Outlet />
        <Footer />
      </div>
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: '/home',
        element: <Home />,
      },
      {
        path: '/profile',
        element: (
          <ProtectedRoute requiredRole={ROLES.USER}>
            <MyProfile />
          </ProtectedRoute>
        ),
        loader: myProfileLoader,
      },
      {
        path: '/salary-calculation',
        element: (
          <ProtectedRoute requiredRole={ROLES.USER}>
            <SalaryCalculation />
          </ProtectedRoute>
        ),
        loader: salaryCalculationLoader,
      },
      {
        path: '/requests',
        element: (
          <ProtectedRoute requiredRoles={ROLES.USER}>
            <MyRequests />
          </ProtectedRoute>
        ),
        loader: myRequestsLoader,
      },
      {
        path: '/balance-account',
        element: (
          <ProtectedRoute requiredRole={ROLES.USER}>
            <BalanceAccount />
          </ProtectedRoute>
        ),
        loader: balanceAccountLoader,
      },
      {
        path: '/expense-reports',
        element: (
          <ProtectedRoute requiredRole={ROLES.USER}>
            <ExpenseReports />
          </ProtectedRoute>
        ),
        loader: expenseReportsLoader,
      },
      {
        path: '/time-reports',
        element: (
          <ProtectedRoute requiredRole={ROLES.USER}>
            <TimeReports />
          </ProtectedRoute>
        ),
        loader: timeReportsLoader,
      },
      {
        path: '/cost-centers',
        element: (
          <ProtectedRoute requiredRole={ROLES.ADMIN}>
            <Outlet />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: <CostCenters />,
            loader: costCentersLoader,
          },
          {
            path: ':id',
            element: <CostCenter />,
          },
        ],
      },
      {
        path: '/employees',
        element: (
          <ProtectedRoute requiredRole={ROLES.ADMIN}>
            <Employees />
          </ProtectedRoute>
        ),
        loader: employeesLoader,
      },
      {
        path: '/fortnox',
        element: (
          <ProtectedRoute requiredRole={ROLES.SYS_ADMIN}>
            <FortnoxLayout />
          </ProtectedRoute>
        ),
        loader: fortnoxLoader,
      },
      {
        path: '/analytics',
        element: (
          <ProtectedRoute requiredRole={ROLES.ADMIN}>
            <AnalyticsLayout />
          </ProtectedRoute>
        ),
        loader: analyticsLoader,
      },
      {
        path: '/healthcheck-transactions',
        element: (
          <ProtectedRoute requiredRole={ROLES.SYS_ADMIN}>
            <HealthcheckTransactions />
          </ProtectedRoute>
        ),
        loader: healthcheckTransactionsLoader,
      },
      {
        path: '/shop',
        element: (
          <ProtectedRoute requiredRoles={(ROLES.USER, ROLES.SYS_ADMIN, ROLES.SHOPKEEPER)}>
            <Shop />
          </ProtectedRoute>
        ),
        loader: shopLoader,
      },
      {
        path: '/orders',
        element: (
          <ProtectedRoute requiredRoles={(ROLES.USER, ROLES.SYS_ADMIN, ROLES.SHOPKEEPER)}>
            <MyOrders />
          </ProtectedRoute>
        ),
        loader: myOrdersLoader,
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/shop/add-article',
        element: (
          <ProtectedRoute requiredRoles={[ROLES.SYS_ADMIN, ROLES.SHOPKEEPER]}>
            <AddArticle />
          </ProtectedRoute>
        ),
      },
      {
        path: '/shop/article-list',
        element: (
          <ProtectedRoute requiredRoles={[ROLES.SYS_ADMIN, ROLES.SHOPKEEPER]}>
            <ArticleList />
          </ProtectedRoute>
        ),
        loader: articleListLoader,
      },
      {
        path: '/shop/orders',
        element: (
          <ProtectedRoute requiredRoles={[ROLES.SYS_ADMIN, ROLES.SHOPKEEPER]}>
            <Orders />
          </ProtectedRoute>
        ),
        loader: ordersLoader,
      },
      {
        path: '/request-list',
        element: (
          <ProtectedRoute requiredRole={ROLES.ADMIN}>
            <RequestsList />
          </ProtectedRoute>
        ),
        loader: requestsListLoader,
      },
      {
        path: '/events',
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute requiredRoles={[ROLES.USER, ROLES.EVENTPLANNER]}>
                <Events />
              </ProtectedRoute>
            ),
            loader: eventsLoader,
          },
          {
            path: ':id',
            element: (
              <ProtectedRoute requiredRoles={[ROLES.USER, ROLES.EVENTPLANNER]}>
                <Events />
              </ProtectedRoute>
            ),
            loader: eventsLoader,
          },
          {
            path: 'create',
            element: (
              <ProtectedRoute requiredRoles={[ROLES.EVENTPLANNER, ROLES.SYS_ADMIN]}>
                <CreateEvent />
              </ProtectedRoute>
            ),
          },
          {
            path: 'manage',
            element: (
              <ProtectedRoute requiredRoles={[ROLES.EVENTPLANNER, ROLES.SYS_ADMIN]}>
                <EventManagement />
              </ProtectedRoute>
            ),
            loader: eventManagementLoader,
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    element: <LoginLayout />,
  },
  {
    path: '/authenticated',
    element: <LoginAuthOk />,
    loader: loginAuthOkLoader,
  },
  {
    path: '/logout',
    element: <LogoutLayout />,
  },
  {
    path: '/unauthorized',
    element: <Unauthorized />,
  },
]);

function App() {
  return (
    <div className="app">
      <div className="container">
        <RouterProvider router={router} />
      </div>
    </div>
  );
}

export default App;
