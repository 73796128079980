import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import Logo from '../images/sinix_white_dot.png';
import { IsAuthenticated } from './user.jsx';
import { theme } from '../utils/theme.jsx';
import { checkUserRole, ROLES } from '../utils/rbac.js';

const Sidebar = () => {
  const auth = IsAuthenticated();
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const closeSidebar = () => {
    setIsOpen(false);
  };

  const renderDivider = () => <div className="menu-divider" />;
  const renderHeader = (text) => (
    <div
      className="menu-header"
      style={{
        padding: '12px 0 8px 4px',
        color: '#FF6B00', // Sinix orange
      }}
    >
      {text}
    </div>
  );

  return (
    <ThemeProvider theme={theme}>
      <div className={`sidebar-overlay ${isOpen ? 'show' : ''}`} onClick={closeSidebar} />

      <button className="mobile-menu-toggle" onClick={toggleSidebar}>
        {isOpen ? <CloseIcon /> : <MenuIcon />}
      </button>

      <div className={`sidebar ${isOpen ? 'open' : ''}`}>
        <div className="logo">
          <Link to="/home" onClick={closeSidebar}>
            <img src={Logo} alt="Logo" />
          </Link>
        </div>

        <div className="menu-items">
          {auth && checkUserRole(ROLES.USER) && (
            <>
              {renderHeader('Personal & Finance')}
              <Link to="/profile" className="menu-item" onClick={closeSidebar}>
                Profile
              </Link>
              <Link to="/requests" className="menu-item" onClick={closeSidebar}>
                Requests
              </Link>
              <Link to="/orders" className="menu-item" onClick={closeSidebar}>
                Shop Orders
              </Link>
              <Link to="/salary-calculation" className="menu-item" onClick={closeSidebar}>
                Salary Calculation
              </Link>
              <Link to="/balance-account" className="menu-item" onClick={closeSidebar}>
                Balance Account
              </Link>
              <Link to="/expense-reports" className="menu-item" onClick={closeSidebar}>
                Expense Reports
              </Link>
              <Link to="/time-reports" className="menu-item" onClick={closeSidebar}>
                Time Reports
              </Link>
              {renderDivider()}
              {renderHeader('Company')}
              <Link to="/events" className="menu-item" onClick={closeSidebar}>
                Events
              </Link>
              <Link to="/shop" className="menu-item" onClick={closeSidebar}>
                Shop
              </Link>
              {renderDivider()}
            </>
          )}

          {(checkUserRole(ROLES.ADMIN) || checkUserRole(ROLES.EVENTPLANNER) || checkUserRole(ROLES.SHOPKEEPER)) && (
            <>
              {renderHeader('Administration')}
              {checkUserRole(ROLES.ADMIN) && (
                <>
                  <Link to="/employees" className="menu-item admin-item" onClick={closeSidebar}>
                    Employees
                  </Link>
                  <Link to="/cost-centers" className="menu-item admin-item" onClick={closeSidebar}>
                    Cost Centers
                  </Link>
                  <Link to="/analytics" className="menu-item admin-item" onClick={closeSidebar}>
                    Analytics
                  </Link>
                  <Link to="/request-list" className="menu-item admin-item" onClick={closeSidebar}>
                    Requests
                  </Link>
                </>
              )}
              {checkUserRole(ROLES.EVENTPLANNER) && (
                <>
                  <Link to="/events/manage" className="menu-item admin-item" onClick={closeSidebar}>
                    Event Management
                  </Link>
                </>
              )}
              {checkUserRole(ROLES.SHOPKEEPER) && (
                <>
                  <Link to="/shop/article-list" className="menu-item admin-item" onClick={closeSidebar}>
                    Shop Articles
                  </Link>
                  <Link to="/shop/orders" className="menu-item admin-item" onClick={closeSidebar}>
                    Shop Orders
                  </Link>
                </>
              )}
              {renderDivider()}
            </>
          )}

          {checkUserRole(ROLES.SYS_ADMIN) && (
            <>
              {renderHeader('System')}
              <Link to="/fortnox" className="menu-item admin-item" onClick={closeSidebar}>
                Fortnox
              </Link>
              <Link to="/healthcheck-transactions" className="menu-item admin-item" onClick={closeSidebar}>
                Healthcheck Transactions
              </Link>
            </>
          )}

          <div style={{ marginTop: '20px' }}>
            {renderDivider()}
            {auth ? (
              <Link to="/logout" className="menu-item" onClick={closeSidebar}>
                LOGOUT
              </Link>
            ) : (
              <Link to="/login" className="menu-item" onClick={closeSidebar}>
                LOGIN
              </Link>
            )}
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default Sidebar;
