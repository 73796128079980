import React, { useState, useEffect } from 'react';
import { Modal, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const EnlargedImageModal = ({ open, handleClose, images, initialImageIndex = 0 }) => {
  const [currentModalImageIndex, setCurrentModalImageIndex] = useState(initialImageIndex);

  useEffect(() => {
    setCurrentModalImageIndex(initialImageIndex);
  }, [initialImageIndex]);

  const handleNext = (e) => {
    e.stopPropagation();
    setCurrentModalImageIndex((prev) => (prev === images.length - 1 ? 0 : prev + 1));
  };

  const handlePrev = (e) => {
    e.stopPropagation();
    setCurrentModalImageIndex((prev) => (prev === 0 ? images.length - 1 : prev - 1));
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="enlarged-image-modal"
      aria-describedby="modal-to-show-enlarged-image"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90vw',
          height: '90vh',
          maxWidth: '1200px',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            zIndex: 1,
          }}
        >
          <CloseIcon />
        </IconButton>

        {images && images.length > 1 && (
          <>
            <IconButton
              onClick={handlePrev}
              sx={{
                position: 'absolute',
                left: 8,
                top: '50%',
                transform: 'translateY(-50%)',
                bgcolor: 'rgba(255,255,255,0.7)',
                '&:hover': { bgcolor: 'rgba(255,255,255,0.9)' },
                zIndex: 1,
              }}
            >
              <ArrowBackIosNewIcon />
            </IconButton>
            <IconButton
              onClick={handleNext}
              sx={{
                position: 'absolute',
                right: 8,
                top: '50%',
                transform: 'translateY(-50%)',
                bgcolor: 'rgba(255,255,255,0.7)',
                '&:hover': { bgcolor: 'rgba(255,255,255,0.9)' },
                zIndex: 1,
              }}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </>
        )}

        <img
          src={images?.[currentModalImageIndex]?.url || images?.[currentModalImageIndex]}
          alt="Enlarged view"
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            width: 'auto',
            height: 'auto',
            objectFit: 'contain',
          }}
        />
      </Box>
    </Modal>
  );
};

export default EnlargedImageModal;
