import React from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Chip,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import { backend } from '../components/user.jsx';
import { IntegrationColor } from '../components/user.jsx';

const PageHeader = styled(Box)(({ theme }) => ({
  background: theme.palette.background.paper,
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  marginBottom: theme.spacing(4),
  boxShadow: theme.shadows[1],
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  '& .MuiTableCell-root': {
    padding: theme.spacing(1.5),
  },
  '& .MuiTableHead-root .MuiTableCell-root': {
    backgroundColor: theme.palette.grey[100],
  },
}));

const EventCard = styled(Card)(({ theme }) => ({
  height: '100%',
  boxShadow: theme.shadows[3],
  '&:hover': {
    boxShadow: theme.shadows[6],
    transform: 'translateY(-2px)',
    transition: 'all 0.3s ease-in-out',
  },
  borderRadius: theme.spacing(2),
  overflow: 'visible',
}));

export function EventManagement() {
  const events = useLoaderData() || [];
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogAction, setDialogAction] = React.useState({ type: null, eventId: null });
  const [hideDeclined, setHideDeclined] = React.useState(false);

  const handleDialogAction = async () => {
    try {
      if (dialogAction.type === 'cancel') {
        await backend.put(`/events/admin/events/${dialogAction.eventId}/cancel`);
      } else if (dialogAction.type === 'delete') {
        await backend.delete(`/events/admin/events/${dialogAction.eventId}`);
      }
      window.location.reload();
    } catch (error) {
      console.error(`Failed to ${dialogAction.type} event:`, error);
      alert(`Failed to ${dialogAction.type} event`);
    }
    setDialogOpen(false);
  };

  const dateTimeFormatOptions = {
    dateTime: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    },
  };

  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      <PageHeader>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h4"
            component="h1"
            sx={{
              fontWeight: 'bold',
              color: (theme) => theme.palette.primary.main,
            }}
          >
            Event Management
          </Typography>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => navigate('/events/create')}
            sx={{
              borderRadius: 2,
              px: 3,
              py: 1,
              boxShadow: 3,
            }}
          >
            Create Event
          </Button>
        </Box>
      </PageHeader>

      {events && events.length > 0 ? (
        <Grid container spacing={3}>
          {events.map((event) => (
            <Grid item key={event.id} xs={12}>
              <EventCard>
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                      mb: 2,
                    }}
                  >
                    <Box>
                      <Typography variant="h6" component="h2">
                        {event.title}
                      </Typography>
                      {event.cancelled && <Chip label="Cancelled" color="error" size="small" sx={{ mt: 1 }} />}
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      {event.integrations ? (
                        event.integrations.map((integration, index) => (
                          <Chip
                            key={index}
                            label={integration}
                            size="small"
                            sx={{
                              backgroundColor: IntegrationColor(integration),
                              color: 'white',
                            }}
                          />
                        ))
                      ) : (
                        <Chip
                          label="group"
                          size="small"
                          sx={{
                            backgroundColor: IntegrationColor('group'),
                            color: 'white',
                          }}
                        />
                      )}
                    </Box>
                  </Box>

                  <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                    <strong>Event Period:</strong>
                    <br />
                    {new Date(event.eventStart).toLocaleDateString('sv-SE', dateTimeFormatOptions.dateTime)} - <br />
                    {new Date(event.eventEnd).toLocaleDateString('sv-SE', dateTimeFormatOptions.dateTime)}
                  </Typography>

                  {event.registrationStart && event.registrationEnd && (
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                      <strong>Registration Period:</strong>
                      <br />
                      {new Date(event.registrationStart).toLocaleDateString(
                        'sv-SE',
                        dateTimeFormatOptions.dateTime
                      )} - <br />
                      {new Date(event.registrationEnd).toLocaleDateString('sv-SE', dateTimeFormatOptions.dateTime)}
                      <br />
                      {new Date(event.registrationEnd) > new Date() ? (
                        <span>
                          Time until registration ends:{' '}
                          {Math.ceil((new Date(event.registrationEnd) - new Date()) / (1000 * 60 * 60 * 24))} days
                        </span>
                      ) : (
                        <span>Registration period has ended</span>
                      )}
                    </Typography>
                  )}
                  {event.attendees?.some((a) => a.choices) && (
                    <Box sx={{ mb: 2 }}>
                      {Object.entries(
                        event.attendees
                          .filter((a) => a.choices && a.attending)
                          .reduce((acc, attendee) => {
                            attendee.choices.forEach((choice) => {
                              const { name, value } = choice;
                              if (!acc[name]) {
                                acc[name] = {};
                              }
                              acc[name][value] = (acc[name][value] || 0) + 1;
                            });
                            return acc;
                          }, {})
                      ).map(([choiceName, valuesCounts]) => (
                        <Box key={choiceName} sx={{ mb: 2 }}>
                          <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                            <strong>{choiceName}</strong>
                          </Typography>
                          <TableContainer component={Paper} variant="outlined">
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell sx={{ fontWeight: 'bold', width: '70%' }}>Value</TableCell>
                                  <TableCell sx={{ fontWeight: 'bold', width: '30%', textAlign: 'right' }}>
                                    Count
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {Object.entries(valuesCounts).map(([value, count]) => (
                                  <TableRow key={value}>
                                    <TableCell>{value}</TableCell>
                                    <TableCell sx={{ textAlign: 'right' }}>{count}</TableCell>
                                  </TableRow>
                                ))}
                                <TableRow sx={{ '& td': { fontWeight: 'bold' } }}>
                                  <TableCell>Total</TableCell>
                                  <TableCell sx={{ textAlign: 'right' }}>
                                    {Object.values(valuesCounts).reduce((sum, count) => sum + count, 0)}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      ))}
                    </Box>
                  )}
                  {event.attendees?.length > 0 ? (
                    <Box sx={{ mb: 2 }}>
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                        <strong>Attendees:</strong>
                      </Typography>

                      <Box sx={{ mb: 2 }}>
                        <StyledTableContainer component={Paper} variant="outlined" sx={{ mb: 2 }}>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell sx={{ fontWeight: 'bold' }}>Company</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Attending</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Declined</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {[...new Set(event.attendees.map((a) => a.integration))].map((integration) => (
                                <TableRow key={integration}>
                                  <TableCell>
                                    <Chip
                                      label={integration}
                                      size="small"
                                      sx={{
                                        backgroundColor: IntegrationColor(integration),
                                        color: 'white',
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    {event.attendees
                                      .filter((a) => a.integration === integration && a.attending)
                                      .reduce(
                                        (total, attendee) => total + 1 + (attendee.additionalAttendees?.length || 0),
                                        0
                                      )}
                                  </TableCell>
                                  <TableCell>
                                    {
                                      event.attendees.filter((a) => a.integration === integration && !a.attending)
                                        .length
                                    }
                                  </TableCell>
                                </TableRow>
                              ))}
                              <TableRow sx={{ '& td': { fontWeight: 'bold' } }}>
                                <TableCell>Total</TableCell>
                                <TableCell>
                                  {event.attendees
                                    .filter((a) => a.attending)
                                    .reduce(
                                      (total, attendee) => total + 1 + (attendee.additionalAttendees?.length || 0),
                                      0
                                    )}
                                  {event.maxAttendees > 0 && (
                                    <Typography component="span" color="text.secondary">
                                      {' '}
                                      / {event.maxAttendees}
                                    </Typography>
                                  )}
                                </TableCell>
                                <TableCell>{event.attendees.filter((a) => !a.attending).length}</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </StyledTableContainer>
                      </Box>

                      <TableContainer component={Paper} variant="outlined">
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ fontWeight: 'bold' }}>Timestamp</TableCell>
                              <TableCell sx={{ fontWeight: 'bold' }}>Email</TableCell>
                              <TableCell sx={{ fontWeight: 'bold' }}>Company</TableCell>
                              <TableCell sx={{ fontWeight: 'bold' }}>Status</TableCell>
                              <TableCell sx={{ fontWeight: 'bold' }}>Choices</TableCell>
                              <TableCell sx={{ fontWeight: 'bold' }}>Additional Attendees</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {event.attendees
                              .filter((attendee) => !hideDeclined || attendee.attending)
                              .map((attendee, index) => (
                                <TableRow key={index}>
                                  <TableCell>
                                    {new Date(attendee.createdAt).toLocaleDateString(
                                      'sv-SE',
                                      dateTimeFormatOptions.dateTime
                                    )}
                                  </TableCell>
                                  <TableCell>{attendee.email}</TableCell>
                                  <TableCell>
                                    <Chip
                                      label={attendee.integration}
                                      size="small"
                                      sx={{
                                        backgroundColor: IntegrationColor(attendee.integration),
                                        color: 'white',
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      sx={{
                                        color: attendee.attending ? 'success.main' : 'error.main',
                                      }}
                                    >
                                      {attendee.attending ? 'Attending' : 'Not Attending'}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    {attendee.choices
                                      ? attendee.choices.map((choice, idx) => (
                                          <Typography key={idx} variant="body2">
                                            {choice.name}: {choice.value}
                                          </Typography>
                                        ))
                                      : '-'}
                                  </TableCell>
                                  <TableCell>
                                    {attendee.additionalAttendees
                                      ? attendee.additionalAttendees.map((additional, idx) => (
                                          <Typography key={idx} variant="body2">
                                            {additional}
                                          </Typography>
                                        ))
                                      : '-'}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  ) : event.rsvp?.attending && event.rsvp.additionalAttendees ? (
                    <Box sx={{ mb: 2 }}>
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                        <strong>Attendees:</strong>
                      </Typography>
                      <TableContainer component={Paper} variant="outlined">
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ fontWeight: 'bold' }}>#</TableCell>
                              <TableCell sx={{ fontWeight: 'bold' }}>Timestamp</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>1</TableCell>
                              <TableCell>
                                {new Date().toLocaleDateString('sv-SE', dateTimeFormatOptions.dateTime)}
                              </TableCell>
                            </TableRow>
                            {event.rsvp.additionalAttendees.map((attendee, index) => (
                              <TableRow key={index}>
                                <TableCell>{index + 2}</TableCell>
                                <TableCell>{attendee}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  ) : null}

                  {event.attendees?.some((a) => a.message) && (
                    <Box sx={{ mb: 2 }}>
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                        <strong>Messages:</strong>
                      </Typography>
                      <TableContainer component={Paper} variant="outlined">
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ fontWeight: 'bold' }}>Timestamp</TableCell>
                              <TableCell sx={{ fontWeight: 'bold' }}>Email</TableCell>
                              <TableCell sx={{ fontWeight: 'bold', width: '70%' }}>Message</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {event.attendees
                              .filter((a) => a.message)
                              .map((attendee, index) => (
                                <TableRow
                                  key={index}
                                  sx={{
                                    backgroundColor: attendee.attending
                                      ? 'rgba(76, 175, 80, 0.2)'
                                      : 'rgba(255, 0, 0, 0.1)',
                                  }}
                                >
                                  <TableCell>
                                    {new Date(attendee.createdAt).toLocaleDateString(
                                      'sv-SE',
                                      dateTimeFormatOptions.dateTime
                                    )}
                                  </TableCell>
                                  <TableCell>{attendee.email}</TableCell>
                                  <TableCell>{attendee.message}</TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  )}

                  <Box sx={{ mt: 2 }}>
                    <Typography variant="body2" color="text.secondary">
                      <strong>Created:</strong>{' '}
                      {new Date(event.createdAt).toLocaleDateString('sv-SE', dateTimeFormatOptions.dateTime)} by{' '}
                      {event.createdBy}
                      {event.updatedAt && (
                        <>
                          <br />
                          <strong>Updated:</strong>{' '}
                          {new Date(event.updatedAt).toLocaleDateString('sv-SE', dateTimeFormatOptions.dateTime)} by{' '}
                          {event.updatedBy}
                        </>
                      )}
                    </Typography>
                  </Box>

                  <Box sx={{ display: 'flex', gap: 1, mt: 3 }}>
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{
                        borderRadius: 2,
                        '&:hover': {
                          transform: 'translateY(-1px)',
                          transition: 'transform 0.2s',
                        },
                      }}
                      onClick={() => navigate(`/events/create?id=${event.id}`)}
                    >
                      Edit
                    </Button>
                    {!event.cancelled && (
                      <Button
                        variant="outlined"
                        size="small"
                        color="warning"
                        onClick={() => {
                          setDialogAction({ type: 'cancel', eventId: event.id });
                          setDialogOpen(true);
                        }}
                      >
                        Cancel Event
                      </Button>
                    )}
                    {(!event.attendees || event.attendees?.length === 0) && (
                      <Button
                        variant="outlined"
                        size="small"
                        color="error"
                        onClick={() => {
                          setDialogAction({ type: 'delete', eventId: event.id });
                          setDialogOpen(true);
                        }}
                      >
                        Delete
                      </Button>
                    )}
                    <Button variant="outlined" size="small" onClick={() => setHideDeclined(!hideDeclined)}>
                      {hideDeclined ? 'Show Declined' : 'Hide Declined'}
                    </Button>
                  </Box>
                </CardContent>
              </EventCard>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Alert severity="info">No events found. Create a new event to get started.</Alert>
      )}

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>{dialogAction.type === 'cancel' ? 'Cancel Event' : 'Delete Event'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {dialogAction.type === 'cancel'
              ? 'Are you sure you want to cancel this event? This action cannot be undone.'
              : 'Are you sure you want to delete this event? This action cannot be undone.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>No, Keep It</Button>
          <Button
            onClick={handleDialogAction}
            color={dialogAction.type === 'cancel' ? 'warning' : 'error'}
            variant="contained"
            autoFocus
          >
            Yes, {dialogAction.type === 'cancel' ? 'Cancel' : 'Delete'} Event
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export async function eventManagementLoader() {
  try {
    const response = await backend.get('/events/admin/events');

    if (response.status !== 200) {
      throw new Response('Failed to fetch events', {
        status: response.status,
        statusText: response.statusText,
      });
    }

    return response.data;
  } catch (err) {
    if (err?.status) {
      throw new Response('', { status: err.status });
    }
    throw err;
  }
}
