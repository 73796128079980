import React, { useState } from 'react';
import {
  Typography,
  Box,
  Card,
  Button,
  Grid,
  Chip,
  Avatar,
  AvatarGroup,
  Container,
  Divider,
  Paper,
  Snackbar,
  Alert,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import { useNavigate, useLoaderData } from 'react-router-dom';
import { backend } from '../components/user.jsx';
import EnlargedImageModal from '../components/EnlargedImageModal.jsx';
import { theme, getRequestStatusColor } from '../utils/theme.jsx';

const ArticleCard = ({ article }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  return (
    <Paper
      elevation={0}
      sx={{
        p: 2,
        borderRadius: 2,
        backgroundColor: theme.palette.grey[50],
        '&:hover': {
          backgroundColor: theme.palette.grey[100],
          transition: 'background-color 0.3s',
        },
      }}
    >
      <Box sx={{ display: 'flex', gap: 2 }}>
        <AvatarGroup
          max={1}
          sx={{
            '& .MuiAvatar-root': { width: 80, height: 80, borderRadius: 2 },
          }}
        >
          {article.images?.map((image, index) => (
            <Avatar
              key={index}
              alt={image.name}
              src={image.url}
              onClick={() => setSelectedImage(image)}
              sx={{ cursor: 'pointer' }}
            />
          ))}
        </AvatarGroup>

        <Box sx={{ flex: 1 }}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {article.name}
          </Typography>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            {article.collection}
          </Typography>

          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, my: 1 }}>
            {article.attributes.map((attribute) => (
              <Chip
                key={attribute.name}
                label={`${attribute.name}: ${attribute.quantity}`}
                size="small"
                variant="outlined"
              />
            ))}
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
            <Typography variant="body2" color="text.secondary">
              Price per item: {article.price.toFixed(2)} Kr
            </Typography>
            <Typography variant="subtitle2" color="primary">
              Total: {(article.price * article.attributes.reduce((sum, attr) => sum + attr.quantity, 0)).toFixed(2)} Kr
            </Typography>
          </Box>
        </Box>
      </Box>

      <EnlargedImageModal
        open={Boolean(selectedImage)}
        handleClose={() => setSelectedImage(null)}
        imageSrc={selectedImage?.url || ''}
      />
    </Paper>
  );
};

const OrderCard = ({ order, onCancelOrder }) => {
  const totalAmount = order.articles.reduce((sum, article) => {
    const articleTotal = article.price * article.attributes.reduce((attrSum, attr) => attrSum + attr.quantity, 0);
    return sum + articleTotal;
  }, 0);

  return (
    <Card
      sx={{
        mb: 3,
        borderRadius: 2,
        boxShadow: theme.shadows[2],
        width: '100%',
        '&:hover': {
          boxShadow: theme.shadows[4],
          transition: 'box-shadow 0.3s',
        },
      }}
    >
      <Box
        sx={{
          p: 2,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Avatar sx={{ bgcolor: getRequestStatusColor(order.status) }}>
            <ShoppingBagIcon />
          </Avatar>

          <Box>
            <Typography variant="h6">Order #{order.id}</Typography>
            <Typography variant="body2" color="text.secondary">
              {new Date(order.timestamp).toLocaleDateString('sv-SE', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, ml: 'auto' }}>
          <Chip
            label={order.status.toUpperCase()}
            size="small"
            sx={{
              backgroundColor: getRequestStatusColor(order.status),
              color: 'white',
              fontWeight: 'bold',
            }}
          />
        </Box>
      </Box>

      <Divider />
      <Box sx={{ p: 3 }}>
        <Grid container spacing={2}>
          {order.articles.map((article) => (
            <Grid item xs={12} key={article.id}>
              <ArticleCard article={article} />
            </Grid>
          ))}
        </Grid>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 3,
          }}
        >
          <Typography variant="h6">Total Amount: {totalAmount.toFixed(2)} Kr</Typography>

          {order.status === 'pending' && (
            <Button
              variant="contained"
              color="error"
              startIcon={<CancelIcon />}
              onClick={() => onCancelOrder(order.id)}
            >
              Cancel Order
            </Button>
          )}
        </Box>
      </Box>
    </Card>
  );
};

const EmptyState = () => {
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        textAlign: 'center',
        py: 8,
        px: 3,
        borderRadius: 2,
      }}
    >
      <ShoppingBagIcon sx={{ fontSize: 60, color: theme.palette.grey[300], mb: 2 }} />
      <Typography variant="h5" gutterBottom>
        No Orders Yet
      </Typography>
      <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
        Your order history is empty. Start shopping to see your orders here!
      </Typography>
      <Button variant="contained" color="primary" onClick={() => navigate('/shop')}>
        Start Shopping
      </Button>
    </Card>
  );
};

export function MyOrders() {
  const orders = useLoaderData();

  const [orderList, setOrderList] = useState(orders);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleCancelOrder = async (orderId) => {
    try {
      const response = await backend.delete(`/shop/orders/${orderId}`);
      if (response.status !== 200) {
        throw new Error('Failed to cancel order');
      }
      setOrderList((prev) => prev.filter((order) => order.id !== orderId));
      setSnackbar({
        open: true,
        message: 'Order cancelled successfully',
        severity: 'success',
      });
    } catch (err) {
      setSnackbar({
        open: true,
        message: 'Failed to cancel order: ' + err.message,
        severity: 'error',
      });
    }
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {orderList.length === 0 ? (
        <EmptyState />
      ) : (
        orderList.map((order) => <OrderCard key={order.id} order={order} onCancelOrder={handleCancelOrder} />)
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{
            width: '100%',
            bgcolor: snackbar.severity === 'error' ? '#FFEBEE' : '#E8F5E9',
            color: snackbar.severity === 'error' ? '#D32F2F' : '#2E7D32',
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export async function myOrdersLoader() {
  try {
    const response = await backend.get('/shop/orders');

    if (response.status !== 200) {
      throw new Response('Failed to fetch orders', {
        status: response.status,
        statusText: response.statusText,
      });
    }

    return response.data;
  } catch (err) {
    if (err?.status) {
      throw new Response('', { status: err.status });
    }
    throw err;
  }
}
