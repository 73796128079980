import { useLoaderData } from 'react-router-dom';
import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Stack, Chip, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { backend } from '../components/user.jsx';

function valToString(val) {
  return val.toLocaleString('default', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'date',
    align: 'left',
    disablePadding: false,
    label: 'Date',
  },
  {
    id: 'id',
    align: 'right',
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'costcenter',
    align: 'left',
    disablePadding: false,
    label: 'Cost-center',
  },
  {
    id: 'vat',
    align: 'right',
    disablePadding: false,
    label: 'VAT',
  },
  {
    id: 'total',
    align: 'right',
    disablePadding: false,
    label: 'Total',
  },
  {
    id: 'description',
    align: 'left',
    disablePadding: false,
    label: 'Description',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            className="table-header"
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export function ExpenseReports() {
  const reports = useLoaderData();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('id');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [searchTerm, setSearchTerm] = React.useState('');

  const filteredReports = React.useMemo(() => {
    return reports.filter(
      (report) =>
        report.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        report.costcenter.toLowerCase().includes(searchTerm.toLowerCase()) ||
        report.id.toString().includes(searchTerm)
    );
  }, [reports, searchTerm]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredReports.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(filteredReports, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, filteredReports]
  );

  const renderMobileView = () => (
    <Stack spacing={2}>
      {visibleRows.map((report) => (
        <Card key={report.id} elevation={2} sx={{ maxWidth: '100%' }}>
          <CardContent>
            <Stack spacing={2}>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h6">ID: {report.id}</Typography>
                <Chip label={report.costcenter} color="primary" size="small" />
              </Stack>
              <Typography color="text.secondary">{report.date}</Typography>
              <Stack spacing={1}>
                <Typography variant="body2">
                  VAT: <strong>{valToString(report.vat)}</strong>
                </Typography>
                <Typography variant="body1">
                  Total: <strong>{valToString(report.total)}</strong>
                </Typography>
              </Stack>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {report.description}
              </Typography>
            </Stack>
          </CardContent>
        </Card>
      ))}
    </Stack>
  );

  const renderDesktopView = () => (
    <Paper elevation={2}>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="small">
          <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
          <TableBody>
            {visibleRows.map((report) => {
              return (
                <TableRow hover key={report.id}>
                  <TableCell component="th" scope="row" align="left" sx={{ width: 100 }}>
                    {report.date}
                  </TableCell>
                  <TableCell align="right" sx={{ width: 80 }}>
                    {report.id}
                  </TableCell>
                  <TableCell align="left" sx={{ width: 120 }}>
                    {report.costcenter}
                  </TableCell>
                  <TableCell align="right" sx={{ width: 80 }}>
                    {valToString(report.vat)}
                  </TableCell>
                  <TableCell align="right" sx={{ width: 100 }}>
                    {valToString(report.total)}
                  </TableCell>
                  <TableCell align="left" sx={{ minWidth: 300 }}>
                    {report.description}
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 33 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30, 40, 50]}
        component="div"
        count={filteredReports.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );

  return (
    <Box sx={{ width: '100%', p: 3 }}>
      <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      {isMobile ? renderMobileView() : renderDesktopView()}
      {isMobile && (
        <TablePagination
          rowsPerPageOptions={[25, 50]}
          component="div"
          count={filteredReports.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
}

export const expenseReportsLoader = async () => {
  try {
    const response = await backend.get('/my/expense-reports');
    if (response.status !== 200) {
      throw new Response('Failed to fetch expense reports', {
        status: response.status,
        statusText: response.statusText,
      });
    }
    return response.data;
  } catch (err) {
    if (err?.status) {
      throw new Response('', { status: err.status });
    }
    throw err;
  }
};
