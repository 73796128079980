import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  Snackbar,
  Grid,
  Input,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Paper,
  Divider,
  MenuItem,
  Select,
  FormControlLabel,
  Switch,
  Chip,
} from '@mui/material';
import dayjs from 'dayjs';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/sv';

import DeleteIcon from '@mui/icons-material/Delete';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { backend, IntegrationColor } from '../components/user.jsx';
import { Integrations } from '../components/user.jsx';

export function CreateEvent() {
  const [event, setEvent] = useState({
    title: '',
    description: '',
    location: '',
    eventStart: '',
    eventEnd: '',
    registrationStart: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    registrationEnd: '',
    maxAdditionalRsvpAttendees: 0,
    maxAttendees: 0,
    integrations: [],
    showAdditionalRsvpAttendees: false,
    showMaxAttendees: false,
    requiresRsvp: false,
    allowRsvpMessage: false,
    choices: [],
    showChoices: false,
  });
  const [images, setImages] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [selectedIntegrations, setSelectedIntegrations] = useState([]);
  const [newChoiceGroup, setNewChoiceGroup] = useState({
    name: '',
    values: [],
  });
  const [newValueText, setNewValueText] = useState('');

  const location = useLocation();
  const integrations = Integrations();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id = params.get('id');
    if (id) {
      setIsEditing(true);
      fetchEvent(id);
    }
  }, [location]);

  const fetchEvent = async (id) => {
    try {
      const response = await backend.get(`/events/admin/events/${id}`);
      if (response.status === 200 && response.data) {
        const eventData = {
          id: response.data.id,
          title: response.data.title || '',
          description: response.data.description || '',
          location: response.data.location || '',
          eventStart: response.data.eventStart || dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          eventEnd: response.data.eventEnd || dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          registrationStart: response.data.registrationStart || dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          registrationEnd: response.data.registrationEnd || dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          maxAdditionalRsvpAttendees: response.data.maxAdditionalRsvpAttendees || 0,
          maxAttendees: response.data.maxAttendees || 0,
          integrations: response.data.integrations || [],
          requiresRsvp: Boolean(response.data.registrationStart),
          showMaxAttendees: Boolean(response.data.maxAttendees),
          showAdditionalRsvpAttendees: Boolean(response.data.maxAdditionalRsvpAttendees),
          allowRsvpMessage: Boolean(response.data.allowRsvpMessage),
          choices: response.data.choices || [],
          showChoices: Boolean(response.data.choices?.length),
        };

        setSelectedIntegrations(eventData.integrations);
        setEvent(eventData);

        // Handle images
        if (response.data.images && Array.isArray(response.data.images)) {
          const existingImages = response.data.images
            .filter(img => img != null)
            .map((img, index) => ({
              id: img.id,
              name: img.name || `Image ${index + 1}`,
              type: img.type || 'image/jpeg',
              data: img.data || null,
            }));
          setImages(existingImages);
        }
      }
    } catch (error) {
      console.error('Error fetching event:', error);
      setSnackbarMessage('Error fetching event details');
      setSnackbarOpen(true);
    }
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setEvent((prevEvent) => ({
      ...prevEvent,
      [name]:
        name === 'requiresRsvp' ||
        name === 'showMaxAttendees' ||
        name === 'showAdditionalRsvpAttendees' ||
        name === 'showChoices' ||
        name === 'allowRsvpMessage'
          ? checked
          : value,
      choices: name === 'showChoices' && checked ? [] : prevEvent.choices,
    }));
  };

  const handleImageChange = (e) => {
    if (e.target.files) {
      setImages((prevImages) => [...prevImages, ...Array.from(e.target.files)]);
    }
  };

  const handleRemoveImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleDateChange = (field) => (date) => {
    setEvent((prevEvent) => {
      const updates = {
        [field]: date ? dayjs(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ') : null,
      };

      // If setting eventStart, update eventEnd and registrationEnd
      if (field === 'eventStart' && date) {
        updates.eventEnd = date.format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        updates.registrationEnd = date.subtract(1, 'day').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      }

      return { ...prevEvent, ...updates };
    });
  };

  const handleIntegrationChange = (event) => {
    setSelectedIntegrations(event.target.value);
    setEvent((prevEvent) => ({
      ...prevEvent,
      integrations: event.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Validation for choices and choice group name
      if (event.requiresRsvp && event.showChoices) {
        if (!event.choices.length || !event.choices.every((opt) => opt.name.trim())) {
          setSnackbarMessage('All choice groups must have non-empty names when choices are enabled');
          setSnackbarOpen(true);
          return;
        }
      }

      // Validate dates
      if (dayjs(event.eventEnd).isBefore(dayjs(event.eventStart))) {
        setSnackbarMessage('Event end time must be after start time');
        setSnackbarOpen(true);
        return;
      }

      // Create a new object with only the required fields
      const eventData = {
        title: event.title,
        description: event.description,
        location: event.location,
        eventStart: event.eventStart,
        eventEnd: event.eventEnd,
      };

      // Add optional fields only if RSVP is required and they are enabled/filled
      if (event.requiresRsvp) {
        eventData.registrationStart = event.registrationStart;
        eventData.registrationEnd = event.registrationEnd;
        eventData.allowRsvpMessage = Boolean(event.allowRsvpMessage);

        if (event.showMaxAttendees && event.maxAttendees > 0) {
          eventData.maxAttendees = parseInt(event.maxAttendees, 10);
        }

        if (event.showAdditionalRsvpAttendees && event.maxAdditionalRsvpAttendees > 0) {
          eventData.maxAdditionalRsvpAttendees = event.maxAdditionalRsvpAttendees;
        }

        // Add choices only if RSVP is required and choices are enabled
        if (event.showChoices && event.choices.length > 0) {
          eventData.choices = event.choices;
        }
      }

      if (selectedIntegrations.length > 0) {
        eventData.integrations = selectedIntegrations;
      }

      // Convert images to binary only for new images
      if (images && images.length > 0) {
        eventData.images = await Promise.all(
          images.map(async (image) => {
            // If the image already has a data property (existing image), return it with id
            if (image.data) {
              return {
                id: image.id,
                name: image.name,
                type: image.type,
                data: image.data,
              };
            }
            // Handle new images as before
            return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onloadend = () =>
                resolve({
                  name: image.name,
                  type: image.type,
                  data: reader.result.split(',')[1],
                });
              reader.onerror = reject;
              reader.readAsDataURL(image);
            });
          })
        );
      }

      // Add event ID if editing
      if (isEditing) {
        eventData.id = event.id;
      }

      const response = isEditing
        ? await backend.put(`/events/admin/events/${event.id}`, eventData)
        : await backend.post('/events/admin/events', eventData);

      if (response.status === 200 || response.status === 201) {
        setSnackbarMessage(isEditing ? 'Event updated successfully' : 'Event created successfully');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Error details:', {
        message: error.message,
        response: error.response?.data,
        ...(typeof eventData !== 'undefined' && { eventData })
      });
      setSnackbarMessage(
        `Failed to process event: ${error.response?.data?.message || error.message || 'Unknown error'}`
      );
      setSnackbarOpen(true);
    }
  };

  const handleAddChoice = () => {
    if (newChoiceGroup.name.trim() && newChoiceGroup.values.length > 0) {
      setEvent((prevEvent) => ({
        ...prevEvent,
        choices: [...prevEvent.choices, { ...newChoiceGroup }],
      }));
      setNewChoiceGroup({ name: '', values: [] });
    }
  };

  const handleAddValue = () => {
    if (newValueText.trim()) {
      setNewChoiceGroup((prev) => ({
        ...prev,
        values: [...prev.values, newValueText.trim()],
      }));
      setNewValueText('');
    }
  };

  const handleRemoveChoiceGroup = (index) => {
    setEvent((prevEvent) => ({
      ...prevEvent,
      choices: prevEvent.choices.filter((_, i) => i !== index),
    }));
  };

  const handleEditChoiceGroup = (groupIndex, newValues) => {
    setEvent((prevEvent) => ({
      ...prevEvent,
      choices: prevEvent.choices.map((group, index) =>
        index === groupIndex ? { ...group, values: newValues } : group
      ),
    }));
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 4, mt: 4, mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ mb: 4 }}>
          {isEditing ? 'Edit Event' : 'Create New Event'}
        </Typography>
        <form onSubmit={handleSubmit} onKeyPress={handleKeyPress}>
          <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
            Required Information
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Event Title"
                name="title"
                value={event.title}
                onChange={handleChange}
                required
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                name="description"
                value={event.description}
                onChange={handleChange}
                multiline
                rows={4}
                required
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Location"
                name="location"
                value={event.location}
                onChange={handleChange}
                required
                variant="outlined"
              />
            </Grid>
          </Grid>

          <Box my={4}>
            <Typography variant="h6" gutterBottom>
              Event Schedule
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sv">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <DateTimePicker
                    label="Event Start"
                    value={event.eventStart ? dayjs(event.eventStart) : null}
                    onChange={handleDateChange('eventStart')}
                    slotProps={{ textField: { fullWidth: true } }}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DateTimePicker
                    label="Event End"
                    value={event.eventEnd ? dayjs(event.eventEnd) : null}
                    onChange={handleDateChange('eventEnd')}
                    slotProps={{ textField: { fullWidth: true } }}
                    required
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
          </Box>

          <Divider sx={{ my: 4 }} />

          <Typography variant="h6" gutterBottom>
            Optional Settings
          </Typography>

          <Box my={4}>
            <Typography variant="subtitle1" gutterBottom>
              Registration Settings
            </Typography>
            <FormControlLabel
              control={<Switch checked={event.requiresRsvp} onChange={handleChange} name="requiresRsvp" />}
              label="RSVP Required"
            />

            {event.requiresRsvp && (
              <>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sv">
                  <Grid container spacing={3} sx={{ mt: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <DateTimePicker
                        label="Registration Start"
                        value={event.registrationStart ? dayjs(event.registrationStart) : null}
                        onChange={handleDateChange('registrationStart')}
                        slotProps={{ textField: { fullWidth: true } }}
                        required={event.requiresRsvp}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DateTimePicker
                        label="Registration End"
                        value={event.registrationEnd ? dayjs(event.registrationEnd) : null}
                        onChange={handleDateChange('registrationEnd')}
                        slotProps={{ textField: { fullWidth: true } }}
                        required={event.requiresRsvp}
                      />
                    </Grid>
                  </Grid>
                </LocalizationProvider>

                <Box sx={{ mt: 2 }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={event.showAdditionalRsvpAttendees}
                        onChange={handleChange}
                        name="showAdditionalRsvpAttendees"
                      />
                    }
                    label="Allow Additional Attendees per RSVP"
                  />
                  {event.showAdditionalRsvpAttendees && (
                    <TextField
                      fullWidth
                      label="Maximum Additional Attendees per RSVP"
                      name="maxAdditionalRsvpAttendees"
                      type="number"
                      value={event.maxAdditionalRsvpAttendees}
                      onChange={handleChange}
                      required={event.showAdditionalRsvpAttendees}
                      variant="outlined"
                      InputProps={{ inputProps: { min: 0 } }}
                      sx={{ mt: 2 }}
                    />
                  )}
                </Box>
                <Box sx={{ mt: 2 }}>
                  <>
                    <FormControlLabel
                      control={
                        <Switch checked={event.showMaxAttendees} onChange={handleChange} name="showMaxAttendees" />
                      }
                      label="Limit Total Attendees"
                    />
                    {event.showMaxAttendees && (
                      <TextField
                        fullWidth
                        label="Maximum Total Attendees"
                        name="maxAttendees"
                        type="number"
                        value={event.maxAttendees}
                        onChange={handleChange}
                        required={event.showMaxAttendees}
                        variant="outlined"
                        InputProps={{ inputProps: { min: 0 } }}
                        sx={{ mt: 2 }}
                      />
                    )}
                  </>
                </Box>

                <Box sx={{ mt: 2 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Choices
                  </Typography>
                  <FormControlLabel
                    control={<Switch checked={event.showChoices} onChange={handleChange} name="showChoices" />}
                    label="Add Choice"
                  />

                  {event.showChoices && (
                    <Box sx={{ mt: 2 }}>
                      {event.choices.map((choiceGroup, groupIndex) => (
                        <Box key={groupIndex} sx={{ mb: 3, p: 2, border: '1px solid #e0e0e0', borderRadius: 1 }}>
                          <Typography variant="subtitle2">{choiceGroup.name}</Typography>
                          <List>
                            {choiceGroup.values.map((value, valueIndex) => (
                              <ListItem key={valueIndex}>
                                <ListItemText primary={value} />
                                <ListItemSecondaryAction>
                                  <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={() => {
                                      const newValues = choiceGroup.values.filter((_, idx) => idx !== valueIndex);
                                      handleEditChoiceGroup(groupIndex, newValues);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            ))}
                          </List>
                          <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
                            <TextField
                              size="small"
                              placeholder="Add new value, press enter to save"
                              onKeyPress={(e) => {
                                if (e.key === 'Enter' && e.target.value.trim()) {
                                  const newValues = [...choiceGroup.values, e.target.value.trim()];
                                  handleEditChoiceGroup(groupIndex, newValues);
                                  e.target.value = '';
                                }
                              }}
                              fullWidth
                            />
                          </Box>
                          <Button
                            size="small"
                            startIcon={<DeleteIcon />}
                            onClick={() => handleRemoveChoiceGroup(groupIndex)}
                            sx={{ mt: 1 }}
                          >
                            Remove Choice Group
                          </Button>
                        </Box>
                      ))}

                      <Box sx={{ mt: 3, p: 2, border: '1px dashed #e0e0e0', borderRadius: 1 }}>
                        <Typography variant="subtitle2" gutterBottom>
                          Add New Choice Group
                        </Typography>
                        <TextField
                          fullWidth
                          label="Choice Group Name"
                          value={newChoiceGroup.name}
                          onChange={(e) => setNewChoiceGroup((prev) => ({ ...prev, name: e.target.value }))}
                          size="small"
                          sx={{ mb: 2 }}
                        />

                        <TextField
                          fullWidth
                          label="New Value"
                          value={newValueText}
                          onChange={(e) => setNewValueText(e.target.value)}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter' && newValueText.trim()) {
                              e.preventDefault();
                              handleAddValue();
                            }
                          }}
                          size="small"
                        />

                        {newChoiceGroup.values.length > 0 && (
                          <List>
                            {newChoiceGroup.values.map((value, index) => (
                              <ListItem key={index}>
                                <ListItemText primary={value} />
                              </ListItem>
                            ))}
                          </List>
                        )}

                        <Button
                          variant="contained"
                          onClick={handleAddChoice}
                          disabled={!newChoiceGroup.name.trim() || newChoiceGroup.values.length === 0}
                          sx={{ mt: 2 }}
                        >
                          Save Choice Group
                        </Button>
                      </Box>
                    </Box>
                  )}
                </Box>

                <Box sx={{ mt: 2 }}>
                  <FormControlLabel
                    control={
                      <Switch checked={event.allowRsvpMessage} onChange={handleChange} name="allowRsvpMessage" />
                    }
                    label="Allow RSVP Message"
                  />
                </Box>
              </>
            )}
          </Box>

          <Box my={4}>
            <Typography variant="subtitle1" gutterBottom>
              Images
            </Typography>
            <Button variant="outlined" component="label" startIcon={<AddPhotoAlternateIcon />} sx={{ mb: 2 }}>
              Upload Images
              <Input
                type="file"
                onChange={handleImageChange}
                sx={{ display: 'none' }}
                inputProps={{ accept: 'image/*', multiple: true }}
              />
            </Button>
            <List>
              {images.map((image, index) => (
                <ListItem key={index}>
                  <ListItemText primary={image.name} />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveImage(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Box>

          <Box my={4}>
            <Typography variant="subtitle1" gutterBottom>
              Integrations
            </Typography>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              Selecting integration(s) is optional and if omitted the event will be visible for group.
            </Typography>
            <Select
              multiple
              fullWidth
              value={selectedIntegrations}
              onChange={handleIntegrationChange}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} sx={{ backgroundColor: IntegrationColor(value) }} />
                  ))}
                </Box>
              )}
            >
              {Object.entries(integrations).map(([name, integration]) => (
                <MenuItem key={name} value={name}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Box
                      sx={{
                        width: 16,
                        height: 16,
                        borderRadius: '50%',
                        backgroundColor: IntegrationColor(name),
                      }}
                    />
                    {name}
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            sx={{ mt: 4, mb: 2, py: 1.5 }}
          >
            {isEditing ? 'Update Event' : 'Create Event'}
          </Button>
        </form>
      </Paper>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Container>
  );
}
