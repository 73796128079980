import React, { useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import {
  Container,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@mui/material';
import { backend, IntegrationColor } from '../components/user.jsx';
import { getRequestStatusColor } from '../utils/theme.jsx';
import { format, parseISO } from 'date-fns';

const formatAmount = (amount) => {
  if (!amount) return 'N/A';
  return `${amount.toLocaleString('sv-SE')} Kr`;
};

export function RequestsList() {
  const requests = useLoaderData() || [];
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [actionType, setActionType] = useState('');
  const [responseMessage, setResponseMessage] = useState('');

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    return format(parseISO(dateString), 'yyyy-MM-dd HH:mm');
  };

  const handleActionClick = (request, action) => {
    setSelectedRequest(request);
    setActionType(action);
    setDialogOpen(true);
    setResponseMessage('');
  };

  const handleSubmitAction = async () => {
    try {
      const response = await backend.put(`/change-requests/${selectedRequest.id}/status`, {
        status: actionType,
        responseMessage,
      });

      if (response.status === 200) {
        setSnackbarMessage(`Request ${actionType}ed successfully`);
        setSnackbarOpen(true);
        window.location.reload();
      }
    } catch (error) {
      console.error(`Error ${actionType}ing request:`, error);
      setSnackbarMessage(`Failed to ${actionType} request`);
      setSnackbarOpen(true);
    }
    setDialogOpen(false);
  };

  return (
    <Container maxWidth={false}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>ID</strong>
              </TableCell>
              <TableCell>
                <strong>Type</strong>
              </TableCell>
              <TableCell>
                <strong>Status</strong>
              </TableCell>
              <TableCell>
                <strong>Amount</strong>
              </TableCell>
              <TableCell>
                <strong>Request Message</strong>
              </TableCell>
              <TableCell>
                <strong>Created By</strong>
              </TableCell>
              <TableCell>
                <strong>Company</strong>
              </TableCell>
              <TableCell>
                <strong>Created At</strong>
              </TableCell>
              <TableCell>
                <strong>Response Message</strong>
              </TableCell>
              <TableCell>
                <strong>Updated By</strong>
              </TableCell>
              <TableCell>
                <strong>Updated At</strong>
              </TableCell>
              <TableCell>
                <strong>Actions</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {requests && requests.length > 0 ? (
              requests.map((request) => (
                <TableRow key={request.id}>
                  <TableCell>{request.id}</TableCell>
                  <TableCell>{request.type.charAt(0).toUpperCase() + request.type.slice(1)}</TableCell>
                  <TableCell>
                    <Chip
                      label={request.status.charAt(0).toUpperCase() + request.status.slice(1)}
                      style={{
                        backgroundColor: getRequestStatusColor(request.status),
                        color: 'white',
                      }}
                    />
                  </TableCell>
                  <TableCell>{request.amount ? formatAmount(request.amount) : 'N/A'}</TableCell>
                  <TableCell>{request.requestMessage}</TableCell>
                  <TableCell>{request.username}</TableCell>
                  <TableCell>
                    {request.integration ? (
                      <Chip
                        label={request.integration}
                        style={{
                          backgroundColor: IntegrationColor(request.integration),
                          color: 'white',
                        }}
                      />
                    ) : (
                      <Chip
                        label="Group"
                        style={{
                          backgroundColor: IntegrationColor('group'),
                          color: 'white',
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell>{formatDate(request.createdAt)}</TableCell>
                  <TableCell>{request.responseMessage || 'N/A'}</TableCell>
                  <TableCell>{request.updatedBy || 'N/A'}</TableCell>
                  <TableCell>{formatDate(request.updatedAt) || 'N/A'}</TableCell>
                  <TableCell>
                    {request.status === 'pending' && (
                      <>
                        <Button
                          variant="contained"
                          color="success"
                          size="small"
                          onClick={() => handleActionClick(request, 'accepted')}
                          sx={{ mr: 1, bgcolor: '#90EE90', '&:hover': { bgcolor: '#7CCD7C' } }}
                        >
                          Accept
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          size="small"
                          onClick={() => handleActionClick(request, 'rejected')}
                          sx={{ bgcolor: '#FF6B6B', '&:hover': { bgcolor: '#FF4040' } }}
                        >
                          Reject
                        </Button>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={12} align="center">
                  No requests found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>{actionType === 'accept' ? 'Accept' : 'Reject'} Request</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Response Message (Optional)"
            fullWidth
            multiline
            rows={4}
            value={responseMessage}
            onChange={(e) => setResponseMessage(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleSubmitAction} variant="contained" color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Container>
  );
}

export const requestsListLoader = async () => {
  try {
    const response = await backend.get('/change-requests');
    if (response.status !== 200) {
      throw new Response('Failed to fetch change requests', { status: response.status });
    }
    const requestsArray = Object.values(response.data || {});
    const sortedRequests = requestsArray.sort((a, b) => b.id - a.id);
    return sortedRequests;
  } catch (err) {
    if (err?.status) {
      throw new Response('', { status: err.status });
    }
    throw err;
  }
};
