import React, { useState, useEffect } from 'react';
import { config } from '../components/config.jsx';
import { 
  Card, 
  CardContent, 
  CardHeader, 
  CardActions, 
  Button, 
  Typography, 
  Grid, 
  Container, 
  Box,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Divider,
  Chip,
  Tooltip as MuiTooltip,
  MenuItem,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import SlackLogo from '../images/slack.png';
import { IntegrationColor, LastLogin } from '../components/user.jsx';

// Article type definition for reference
const sampleArticles = [{
  id: 1,
  title: 'Sample Article',
  createdAt: '2025-01-26',
  createdBy: 'John Doe',
  updatedAt: null,
  updatedBy: null,
  integrations: ['group', 'slack'],
  sections: [
    {
      type: 'text',
      heading: 'Welcome to our latest update!',
      content: 'Welcome to our latest update! This article demonstrates how we can display rich content with text and images side by side. The layout system allows for flexible positioning and sizing of different content blocks. This example shows how text can flow naturally while maintaining readability and visual hierarchy. Feel free to experiment with different content lengths and arrangements to find the perfect layout for your needs.',
      width: '100',
      position: 'auto'
    },
    {
      type: 'image',
      url: 'https://cdn3.pixelcut.app/7/20/uncrop_hero_bdf08a8ca6.jpg',
      caption: 'This image will take up 50% of the space',
      width: '50'
    },
    {
      type: 'text',
      heading: '<--- Welcome to our latest update!',
      content: 'This section will take up available space automatically2',
      width: '50'
    },
    {
      type: 'text',
      heading: 'Welcome to our latest update! --->',
      content: 'This section will take up available space automatically2',
      width: '75'
    },
    {
      type: 'image',
      url: 'https://cdn3.pixelcut.app/7/20/uncrop_hero_bdf08a8ca6.jpg',
      caption: 'This image will take up 25% of the space',
      width: '25'
    },
  ]
}, {
  id: 2,
  title: 'Sample Article 2',
  createdAt: '2025-01-01',
  createdBy: 'John Doe',
  updatedAt: '2025-01-26',
  updatedBy: 'John Doe',
  integrations: ['group'],
  sections: [
    {
      type: 'text',
      heading: 'Welcome to our latest update!',
      content: 'Welcome to our latest update! This article demonstrates how we can display rich content with text and images side by side. The layout system allows for flexible positioning and sizing of different content blocks. This example shows how text can flow naturally while maintaining readability and visual hierarchy. Feel free to experiment with different content lengths and arrangements to find the perfect layout for your needs.',
      width: '100'
    },
    {
      type: 'image',
      url: 'https://www.piclumen.com/wp-content/uploads/2024/10/piclumen-upscale-after.webp',
      caption: 'This image will take up 75% of the space',
      width: '75',
      position: 'center'
    }
  ]
}];

export const Home = () => {
  const [articles, setArticles] = useState([]);
  const [editingArticle, setEditingArticle] = useState(null);
  const [previewArticle, setPreviewArticle] = useState(null);

  // Fetch articles from your backend
  useEffect(() => {
    // TODO: Implement API call to fetch articles
    setArticles(sampleArticles);
  }, []);

  const renderSection = (section, index) => {
    const sectionStyle = {
      width: `calc(${section.width}% - 8px)`,
      height: 'auto',
      padding: '8px',
      border: '1px dashed #ccc',
      margin: '4px',
      borderRadius: '4px',
      boxSizing: 'border-box',
      ...(section.position === 'left' && { marginRight: 'auto' }),
      ...(section.position === 'right' && { marginLeft: 'auto' }),
      ...(section.position === 'center' && { margin: '4px auto' }),
    };

    switch (section.type) {
      case 'text':
        return (
          <Box 
            key={index} 
            sx={sectionStyle}
          >
            {section.heading && (
              <Typography 
                variant="h6" 
                gutterBottom
              >
                {section.heading}
              </Typography>
            )}
            <Typography 
              variant="body1" 
              paragraph 
            >
              {section.content}
            </Typography>
          </Box>
        );
      case 'image':
        return (
          <Box key={index} sx={{ ...sectionStyle, textAlign: 'center' }}>
            <img 
              src={section.url} 
              alt={section.caption} 
              style={{ 
                width: '100%',
                height: 'auto', 
                borderRadius: 4,
                marginBottom: '8px'
              }}
            />
            <Typography variant="caption" display="block" color="text.secondary">
              {section.caption}
            </Typography>
          </Box>
        );
      default:
        return null;
    }
  };

  const handleCreateOrEdit = (isEdit = false) => {
    const emptyArticle = {
      id: Date.now(),
      title: '',
      createdAt: new Date().toISOString().split('T')[0],
      createdBy: 'Current User',
      sections: []
    };
    
    const articleToEdit = isEdit ? { ...editingArticle } : emptyArticle;
    
    // Ensure sections exists and is an array
    if (!articleToEdit.sections) {
      articleToEdit.sections = [];
    }
    
    setEditingArticle(articleToEdit);
    setPreviewArticle(articleToEdit);
  };

  const handleSave = () => {
    const updatedArticle = {
      ...editingArticle,
      updatedAt: new Date().toISOString().split('T')[0],
      updatedBy: 'Current User'
    };

    if (editingArticle.id) {
      setArticles(articles.map(a => a.id === editingArticle.id ? updatedArticle : a));
    } else {
      setArticles([...articles, updatedArticle]);
    }
    setEditingArticle(null);
    setPreviewArticle(null);
  };

  const handleAddSection = (type) => {
    const newSection = type === 'text' 
      ? { type: 'text', heading: '', content: '', width: '100', position: 'auto' }
      : { type: 'image', url: '', caption: '', width: '100', position: 'auto' };
    
    setEditingArticle({
      ...editingArticle,
      sections: [...editingArticle.sections, newSection]
    });
    setPreviewArticle({
      ...editingArticle,
      sections: [...editingArticle.sections, newSection]
    });
  };

  const handleUpdateSection = (index, field, value) => {
    const updatedSections = editingArticle.sections.map((section, i) => 
      i === index ? { ...section, [field]: value } : section
    );
    
    setEditingArticle({
      ...editingArticle,
      sections: updatedSections
    });
    setPreviewArticle({
      ...editingArticle,
      sections: updatedSections
    });
  };

  const handleDeleteSection = (index) => {
    const updatedSections = editingArticle.sections.filter((_, i) => i !== index);
    setEditingArticle({
      ...editingArticle,
      sections: updatedSections
    });
    setPreviewArticle({
      ...editingArticle,
      sections: updatedSections
    });
  };

  // Replace renderEditorDialog with a side editor component
  const renderEditor = () => (
    <Box sx={{ width: '100%', mb: 2 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        {editingArticle?.id ? 'Edit Article' : 'Create New Article'}
      </Typography>
      <Box sx={{ mb: 2 }}>
        <TextField
          fullWidth
          label="Title"
          value={editingArticle?.title || ''}
          onChange={(e) => {
            setEditingArticle({ ...editingArticle, title: e.target.value });
            setPreviewArticle({ ...previewArticle, title: e.target.value });
          }}
          sx={{ mb: 2 }}
        />
        
        <TextField
          fullWidth
          label="Integrations (comma-separated)"
          value={editingArticle?.integrations?.join(', ') || ''}
          onChange={(e) => {
            const integrations = e.target.value.split(',').map(i => i.trim()).filter(i => i);
            setEditingArticle({ ...editingArticle, integrations });
            setPreviewArticle({ ...previewArticle, integrations });
          }}
          sx={{ mb: 2 }}
          helperText="Enter integration names separated by commas (e.g., 'group, slack')"
        />

        <Box sx={{ mb: 2 }}>
          <Button onClick={() => handleAddSection('text')} sx={{ mr: 1 }}>
            Add Text Section
          </Button>
          <Button onClick={() => handleAddSection('image')}>
            Add Image Section
          </Button>
        </Box>

        {editingArticle?.sections.map((section, index) => (
          <Paper key={index} sx={{ p: 2, mb: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="subtitle1">
                {section.type === 'text' ? 'Text Section' : 'Image Section'}
              </Typography>
              <IconButton onClick={() => handleDeleteSection(index)} size="small">
                <DeleteIcon />
              </IconButton>
            </Box>
            
            <TextField
              fullWidth
              label="Width (%)"
              type="number"
              value={section.width}
              onChange={(e) => handleUpdateSection(index, 'width', e.target.value)}
              sx={{ mb: 1 }}
            />

            <TextField
              select
              fullWidth
              label="Position"
              value={section.position || 'auto'}
              onChange={(e) => handleUpdateSection(index, 'position', e.target.value)}
              sx={{ mb: 1 }}
            >
              {['auto', 'left', 'right', 'center'].map((pos) => (
                <MenuItem key={pos} value={pos}>
                  {pos.charAt(0).toUpperCase() + pos.slice(1)}
                </MenuItem>
              ))}
            </TextField>

            {section.type === 'text' ? (
              <>
                <TextField
                  fullWidth
                  label="Heading"
                  value={section.heading}
                  onChange={(e) => handleUpdateSection(index, 'heading', e.target.value)}
                  sx={{ mb: 1 }}
                />
                <TextField
                  fullWidth
                  label="Content"
                  multiline
                  rows={4}
                  value={section.content}
                  onChange={(e) => handleUpdateSection(index, 'content', e.target.value)}
                />
              </>
            ) : (
              <>
                <TextField
                  fullWidth
                  label="Image URL"
                  value={section.url}
                  onChange={(e) => handleUpdateSection(index, 'url', e.target.value)}
                  sx={{ mb: 1 }}
                />
                <TextField
                  fullWidth
                  label="Caption"
                  value={section.caption}
                  onChange={(e) => handleUpdateSection(index, 'caption', e.target.value)}
                />
              </>
            )}
          </Paper>
        ))}
      </Box>

      <Box sx={{ mt: 2, display: 'flex', gap: 1 }}>
        <Button onClick={() => setEditingArticle(null)}>Cancel</Button>
        <Button onClick={handleSave} variant="contained">
          Save
        </Button>
      </Box>
    </Box>
  );

  return (
    <Container maxWidth="xl" sx={{ py: { xs: 2, md: 4 } }}>
      <Box sx={{ mb: { xs: 2, md: 4 } }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          mb: { xs: 2, md: 3 },
          flexDirection: { xs: 'column', sm: 'row' },
          gap: { xs: 2, sm: 0 }
        }}>
          <Button
            fullWidth={false}
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => handleCreateOrEdit(false)}
            sx={{
              borderRadius: 2,
              px: 3,
              py: 1,
              boxShadow: 2,
              width: { xs: '100%', sm: 'auto' }
            }}
          >
            Create New Article
          </Button>
        </Box>
        
        <Grid container spacing={{ xs: 2, md: 4 }}>
          {/* Editor and preview layout */}
          {editingArticle && (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  {renderEditor()}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" sx={{ mb: 2 }}>Preview</Typography>
                  {previewArticle && (
                    <Card elevation={3}>
                      <CardHeader
                        title={previewArticle.title}
                        subheader={previewArticle.createdAt}
                      />
                      <CardContent>
                        <Box sx={{ 
                          display: 'flex', 
                          flexWrap: 'wrap',
                          alignItems: 'stretch',
                          margin: -0.5,
                          width: 'calc(100% + 8px)',
                          position: 'relative',
                          left: -4,
                        }}>
                          {previewArticle.sections.map((section, index) => renderSection(section, index))}
                        </Box>
                      </CardContent>
                    </Card>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}

          {/* Articles grid */}
          {!editingArticle && articles.map((article) => {
            const lastLogin = LastLogin();
            const isNew = new Date(article.createdAt) > lastLogin;
            const isUpdated = article.updatedAt && new Date(article.updatedAt) > lastLogin;

            return (
              <Grid item xs={12} md={6} key={article.id}>
                <Card 
                  elevation={2}
                  sx={{
                    borderRadius: 3,
                    transition: 'transform 0.2s, box-shadow 0.2s',
                    '&:hover': {
                      transform: { xs: 'none', md: 'translateY(-4px)' },
                      boxShadow: { xs: 2, md: 6 }
                    },
                    position: 'relative',
                    ...(isNew || isUpdated) && {
                      border: '2px solid',
                      borderColor: isNew ? 'success.main' : 'warning.main',
                    }
                  }}
                >
                  <CardHeader
                    title={
                      <Box sx={{ position: 'relative' }}>
                        <Box
                          sx={{
                            position: 'absolute',
                            top: { xs: -8, md: 0 },
                            right: 0,
                            display: 'flex',
                            flexDirection: { xs: 'row', md: 'column' },
                            gap: 0.5,
                            zIndex: 1,
                            flexWrap: 'wrap',
                            justifyContent: 'flex-end'
                          }}
                        >
                          {article.integrations && article.integrations.length > 0 ? (
                            article.integrations.map((integration, index) => (
                              <Chip
                                key={index}
                                label={integration}
                                sx={{
                                  backgroundColor: IntegrationColor(integration),
                                  color: 'white',
                                }}
                              />
                            ))
                          ) : (
                            <Chip
                              label="Group"
                              sx={{
                                backgroundColor: IntegrationColor('group'),
                                color: 'white',
                              }}
                            />
                          )}
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
                          {(isNew || isUpdated) && (
                            <MuiTooltip 
                              title={isNew ? "New article since last login" : "Updated since last login"}
                            >
                              <Typography 
                                sx={{ 
                                  color: isNew ? 'success.main' : 'warning.main',
                                  fontWeight: 'bold',
                                  fontSize: '1.5rem',
                                  lineHeight: 1,
                                }}
                              >
                                ❉
                              </Typography>
                            </MuiTooltip>
                          )}
                          <Typography variant="h5" sx={{ 
                            fontWeight: 600,
                            color: 'text.primary',
                            mb: 1,
                            pr: 8,
                          }}>
                            {article.title}
                          </Typography>
                        </Box>
                      </Box>
                    }
                    subheader={
                      <Box>
                        <Typography 
                          variant="subtitle2" 
                          sx={{ 
                            color: 'text.secondary',
                            fontStyle: 'italic'
                          }}
                        >
                          Created on {new Date(article.createdAt).toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                          })} by {article.createdBy}
                        </Typography>
                        {article.updatedAt && (
                          <Typography 
                            variant="subtitle2" 
                            sx={{ 
                              color: 'text.secondary',
                              fontStyle: 'italic'
                            }}
                          >
                            Last updated on {new Date(article.updatedAt).toLocaleDateString('en-US', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric'
                            })} by {article.updatedBy}
                          </Typography>
                        )}
                      </Box>
                    }
                    sx={{ pb: 1 }}
                  />
                  <CardContent sx={{ pt: 0 }}>
                    <Box sx={{ 
                      display: 'flex', 
                      flexWrap: 'wrap',
                      alignItems: 'stretch',
                      margin: -0.5,
                      width: 'calc(100% + 8px)',
                      position: 'relative',
                      left: -4,
                    }}>
                      {article.sections.map((section, index) => renderSection(section, index))}
                    </Box>
                  </CardContent>
                  <CardActions sx={{ 
                    borderTop: 1, 
                    borderColor: 'divider',
                    px: 2,
                    py: 1.5
                  }}>
                    <Button
                      startIcon={<EditIcon />}
                      onClick={() => {
                        setEditingArticle(article);
                        handleCreateOrEdit(true);
                      }}
                      sx={{
                        borderRadius: 2,
                        '&:hover': {
                          backgroundColor: 'action.hover'
                        }
                      }}
                    >
                      Edit Article
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Box>

      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        gap: 2,
        mt: { xs: 2, md: 4 },
        p: 2,
        bgcolor: 'background.paper',
        borderRadius: 1,
        flexDirection: { xs: 'column', sm: 'row' }
      }}>
        <img 
          width={100} 
          style={{ maxWidth: '100%', height: 'auto' }} 
          src={SlackLogo} 
          alt="Slack Logo" 
        />
        <Button
          fullWidth
          sx={{ width: { xs: '100%', sm: 'auto' } }}
          href="https://sinixab.slack.com/archives/C052LUP1X29"
          target="_blank"
          rel="noreferrer"
          variant="outlined"
        >
          #sunshine
        </Button>
      </Box>
    </Container>
  );
};
