import { useLoaderData } from 'react-router-dom';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tooltip as MuiTooltip,
} from '@mui/material';

import { theme } from '../utils/theme.jsx';

const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

// Add this constant for expected working days
const expectedWorkDays = {
  0: 21, // January
  1: 20, // February
  2: 22, // March
  3: 20, // April
  4: 19, // May
  5: 19, // June
  6: 0, // July
  7: 21, // August
  8: 22, // September
  9: 22, // October
  10: 21, // November
  11: 20, // December
};

export const TimeReportsLayout = (timeReports) => {
  // Handle null or empty timeReports
  if (!timeReports || !Array.isArray(timeReports) || timeReports.length === 0) {
    return (
      <Typography variant="h6" align="center" sx={{ mt: 4, color: 'text.secondary' }}>
        No time reports available
      </Typography>
    );
  }

  // Reorganize data into nested structure
  const organizedData = {};
  const yearlyTotals = {};
  const monthlyTotals = {};
  const weeklyData = {}; // New structure to store weekly data by month

  for (const report of timeReports) {
    const date = new Date(report.date);
    const year = date.getFullYear();
    const month = date.getMonth();
    const week = getWeekNumber(date);
    const code = report.code;
    const type = report.type;
    const hours = report.hours;

    // Initialize structures if they don't exist
    if (!yearlyTotals[year]) yearlyTotals[year] = {};
    if (!monthlyTotals[year]) monthlyTotals[year] = {};
    if (!monthlyTotals[year][month]) monthlyTotals[year][month] = {};

    // Update yearly totals
    const yearKey = `${code}-${type}`;
    if (!yearlyTotals[year][yearKey]) {
      yearlyTotals[year][yearKey] = { code, type, hours: 0 };
    }
    yearlyTotals[year][yearKey].hours += hours;

    // Update monthly totals
    const monthKey = `${code}-${type}`;
    if (!monthlyTotals[year][month][monthKey]) {
      monthlyTotals[year][month][monthKey] = { code, type, hours: 0 };
    }
    monthlyTotals[year][month][monthKey].hours += hours;

    // Add weekly data organization
    if (!weeklyData[year]) weeklyData[year] = {};
    if (!weeklyData[year][month]) weeklyData[year][month] = {};
    if (!weeklyData[year][month][`${code}-${type}`]) {
      weeklyData[year][month][`${code}-${type}`] = {};
    }
    if (!weeklyData[year][month][`${code}-${type}`][week]) {
      weeklyData[year][month][`${code}-${type}`][week] = 0;
    }
    weeklyData[year][month][`${code}-${type}`][week] += hours;

    // Original organizedData structure (unchanged)
    if (!organizedData[year]) organizedData[year] = {};
    if (!organizedData[year][week]) organizedData[year][week] = {};
    if (!organizedData[year][week][code]) organizedData[year][week][code] = {};
    if (!organizedData[year][week][code][type]) organizedData[year][week][code][type] = 0;
    organizedData[year][week][code][type] += hours;
  }

  // Helper function to render weekly details tooltip content
  const WeeklyDetailsTooltip = ({ year, month, code, type }) => {
    const weeklyHours = weeklyData[year]?.[month]?.[`${code}-${type}`] || {};

    return (
      <Paper sx={{ p: 1, maxWidth: 300 }}>
        <Typography variant="subtitle2" gutterBottom>
          Weekly Breakdown
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Week</TableCell>
              <TableCell align="right">Hours</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(weeklyHours).map(([week, hours]) => (
              <TableRow key={week}>
                <TableCell>{week}</TableCell>
                <TableCell align="right">{hours.toFixed(1)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  };

  // Add this helper function near the top of the TimeReports component
  const getFirstReportedMonth = (monthlyTotals, year) => {
    for (let month = 0; month < 12; month++) {
      if (monthlyTotals[year]?.[month] && Object.values(monthlyTotals[year][month]).some((entry) => entry.hours > 0)) {
        return month;
      }
    }
    return 0;
  };

  return (
    <>
      {Object.entries(organizedData)
        .sort(([yearA], [yearB]) => yearB - yearA)
        .map(([year, weeks]) => (
          <div key={year}>
            <Typography
              variant="h4"
              align="center"
              gutterBottom
              sx={{
                mt: 4,
                mb: 4,
                color: 'primary.main',
                fontWeight: 'medium',
              }}
            >
              {year}
            </Typography>

            {/* Monthly Summaries */}
            <TableContainer
              component={Paper}
              sx={{
                mb: 4,
                boxShadow: 2,
                borderRadius: 2,
                overflow: 'auto',
                maxWidth: '100vw',
                '& .MuiTable-root': {
                  borderCollapse: 'separate',
                  borderSpacing: '0',
                  minWidth: '1000px',
                },
                '& .MuiTableCell-head': {
                  backgroundColor: '#2e7d32',
                  color: 'white',
                  fontSize: '0.875rem',
                  whiteSpace: 'nowrap',
                },
                '& .MuiTableCell-body': {
                  fontSize: '0.875rem',
                  borderBottom: '1px solid rgba(224, 224, 224, 0.4)',
                  padding: '8px 16px',
                },
                '& .MuiTableRow-root:hover': {
                  backgroundColor: 'rgba(46, 125, 50, 0.04)',
                },
                '& .MuiTableRow-root:last-child .MuiTableCell-body': {
                  borderBottom: 'none',
                },
              }}
            >
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ backgroundColor: '#2e7d32', color: 'white', fontWeight: 'bold' }}>Code</TableCell>
                    <TableCell sx={{ backgroundColor: '#2e7d32', color: 'white', fontWeight: 'bold' }}>Type</TableCell>
                    {monthNames.map((monthName, index) => (
                      <TableCell
                        key={monthName}
                        align="right"
                        sx={{ backgroundColor: '#2e7d32', color: 'white', fontWeight: 'bold' }}
                      >
                        {monthName}
                      </TableCell>
                    ))}
                    <TableCell align="right" sx={{ backgroundColor: '#1b5e20', color: 'white', fontWeight: 'bold' }}>
                      Yearly Total
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(yearlyTotals[year] || {})
                    .sort(([, a], [, b]) => b.hours - a.hours)
                    .map(([key, { code, type, hours }]) => (
                      <TableRow key={`${year}-${code}-${type}`} hover sx={{ '&:last-child td': { borderBottom: 0 } }}>
                        <TableCell>{code}</TableCell>
                        <TableCell>{type}</TableCell>
                        {Array.from({ length: 12 }, (_, month) => (
                          <TableCell key={month} align="right">
                            {monthlyTotals[year]?.[month]?.[`${code}-${type}`]?.hours ? (
                              <MuiTooltip
                                theme={theme}
                                title={<WeeklyDetailsTooltip year={year} month={month} code={code} type={type} />}
                                arrow
                                placement="right"
                              >
                                <span>{monthlyTotals[year][month][`${code}-${type}`].hours.toFixed(1)}</span>
                              </MuiTooltip>
                            ) : (
                              '-'
                            )}
                          </TableCell>
                        ))}
                        <TableCell align="right" sx={{ backgroundColor: 'grey.50', fontWeight: 'medium' }}>
                          {hours.toFixed(1)}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Hours Comparison */}
            <TableContainer
              component={Paper}
              sx={{
                mb: 4,
                boxShadow: 2,
                borderRadius: 2,
                overflow: 'auto',
                maxWidth: '100vw',
                '& .MuiTable-root': {
                  borderCollapse: 'separate',
                  borderSpacing: '0',
                  minWidth: '1000px',
                },
                '& .MuiTableCell-head': {
                  backgroundColor: '#2e7d32',
                  color: 'white',
                  fontSize: '0.875rem',
                  whiteSpace: 'nowrap',
                },
                '& .MuiTableCell-body': {
                  fontSize: '0.875rem',
                  borderBottom: '1px solid rgba(224, 224, 224, 0.4)',
                  padding: '8px 16px',
                },
                '& .MuiTableRow-root:hover': {
                  backgroundColor: 'rgba(46, 125, 50, 0.04)',
                },
                '& .MuiTableRow-root:last-child .MuiTableCell-body': {
                  borderBottom: 'none',
                },
                '& .MuiTableCell-body:first-of-type': {
                  fontWeight: 'medium',
                  backgroundColor: 'rgba(46, 125, 50, 0.08)',
                },
              }}
            >
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ backgroundColor: '#2e7d32', color: 'white', fontWeight: 'bold' }}>
                      Metric
                    </TableCell>
                    {monthNames.map((monthName, index) => (
                      <TableCell
                        key={monthName}
                        align="right"
                        sx={{ backgroundColor: '#2e7d32', color: 'white', fontWeight: 'bold' }}
                      >
                        {monthName}
                      </TableCell>
                    ))}
                    <TableCell align="right" sx={{ backgroundColor: '#1b5e20', color: 'white', fontWeight: 'bold' }}>
                      Total
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <MuiTooltip
                        title="Rough estimate based on full-time work (8h/day) with 5 weeks of vacation during June/July"
                        arrow
                      >
                        <span>Expected Hours</span>
                      </MuiTooltip>
                    </TableCell>
                    {Array.from({ length: 12 }, (_, month) => (
                      <TableCell key={month} align="right">
                        {month >= getFirstReportedMonth(monthlyTotals, year) ? expectedWorkDays[month] * 8 : '-'}
                      </TableCell>
                    ))}
                    <TableCell align="right" sx={{ backgroundColor: 'grey.50', fontWeight: 'medium' }}>
                      {Object.values(expectedWorkDays)
                        .slice(getFirstReportedMonth(monthlyTotals, year))
                        .reduce((sum, days) => sum + days * 8, 0)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Cumulative Expected</TableCell>
                    {Array.from({ length: 12 }, (_, month) => {
                      const firstMonth = getFirstReportedMonth(monthlyTotals, year);
                      const cumulative =
                        month >= firstMonth
                          ? Array.from(
                              { length: month - firstMonth + 1 },
                              (_, i) => expectedWorkDays[i + firstMonth] * 8
                            ).reduce((sum, hours) => sum + hours, 0)
                          : null;
                      return (
                        <TableCell key={month} align="right">
                          {cumulative !== null ? cumulative : '-'}
                        </TableCell>
                      );
                    })}
                    <TableCell align="right" sx={{ backgroundColor: 'grey.50', fontWeight: 'medium' }}>
                      {Object.values(expectedWorkDays)
                        .slice(getFirstReportedMonth(monthlyTotals, year))
                        .reduce((sum, days) => sum + days * 8, 0)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Actual Hours</TableCell>
                    {Array.from({ length: 12 }, (_, month) => {
                      const monthlyTotal = Object.values(monthlyTotals[year]?.[month] || {})
                        .filter((entry) => entry.type === 'WORK')
                        .reduce((sum, entry) => sum + entry.hours, 0);
                      return (
                        <TableCell
                          key={month}
                          align="right"
                          sx={{
                            color: monthlyTotal < expectedWorkDays[month] * 8 ? 'error.main' : 'success.main',
                          }}
                        >
                          {monthlyTotal ? monthlyTotal.toFixed(1) : '-'}
                        </TableCell>
                      );
                    })}
                    <TableCell align="right" sx={{ backgroundColor: 'grey.50', fontWeight: 'medium' }}>
                      {Object.values(yearlyTotals[year] || {})
                        .filter((entry) => entry.type === 'WORK')
                        .reduce((sum, entry) => sum + entry.hours, 0)
                        .toFixed(1)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Cumulative Actual</TableCell>
                    {Array.from({ length: 12 }, (_, month) => {
                      const firstMonth = getFirstReportedMonth(monthlyTotals, year);

                      if (month >= firstMonth) {
                        const cumulative = Array.from({ length: month - firstMonth + 1 }, (_, i) =>
                          Object.values(monthlyTotals[year]?.[i + firstMonth] || {})
                            .filter((entry) => entry.type === 'WORK')
                            .reduce((sum, entry) => sum + entry.hours, 0)
                        ).reduce((sum, hours) => sum + hours, 0);

                        const expectedCumulative = Array.from(
                          { length: month - firstMonth + 1 },
                          (_, i) => expectedWorkDays[i + firstMonth] * 8
                        ).reduce((sum, hours) => sum + hours, 0);

                        const percentage = expectedCumulative > 0 ? (cumulative / expectedCumulative) * 100 : 0;

                        return (
                          <TableCell
                            key={month}
                            align="right"
                            sx={{
                              color: cumulative < expectedCumulative ? 'error.main' : 'success.main',
                            }}
                          >
                            {cumulative > 0 ? (
                              <div>
                                <div>{cumulative.toFixed(1)}</div>
                                <Typography
                                  component="div"
                                  sx={{
                                    fontSize: '0.75em',
                                    color: 'text.secondary',
                                  }}
                                >
                                  ({percentage.toFixed(1)}%)
                                </Typography>
                              </div>
                            ) : (
                              '-'
                            )}
                          </TableCell>
                        );
                      }

                      return (
                        <TableCell key={month} align="right">
                          -
                        </TableCell>
                      );
                    })}
                    <TableCell align="right" sx={{ backgroundColor: 'grey.50', fontWeight: 'medium' }}>
                      {(() => {
                        const firstMonth = getFirstReportedMonth(monthlyTotals, year);
                        const totalActual = Object.entries(monthlyTotals[year] || {})
                          .filter(([month]) => parseInt(month) >= firstMonth)
                          .reduce(
                            (sum, [, monthData]) =>
                              sum +
                              Object.values(monthData)
                                .filter((entry) => entry.type === 'WORK')
                                .reduce((monthSum, entry) => monthSum + entry.hours, 0),
                            0
                          );

                        const totalExpected = Object.entries(expectedWorkDays)
                          .filter(([month]) => parseInt(month) >= firstMonth)
                          .reduce((sum, [, days]) => sum + days * 8, 0);

                        const totalPercentage = totalExpected > 0 ? (totalActual / totalExpected) * 100 : 0;

                        return totalActual > 0 ? (
                          <div>
                            <div>{totalActual.toFixed(1)}</div>
                            <Typography
                              component="div"
                              sx={{
                                fontSize: '0.75em',
                                color: 'text.secondary',
                              }}
                            >
                              ({totalPercentage.toFixed(1)}%)
                            </Typography>
                          </div>
                        ) : (
                          '-'
                        );
                      })()}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ))}
    </>
  );
};

// Helper function to get week number
function getWeekNumber(date) {
  const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  const dayNum = d.getUTCDay() || 7;
  d.setUTCDate(d.getUTCDate() + 4 - dayNum);
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
}
