import React from 'react';
import { Box, Tooltip } from '@mui/material';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { LastLogin } from './user.jsx';

export const LastLoginIndicator = ({ createdAt, updatedAt }) => {
  const lastLoginDate = LastLogin();
  const createdDate = new Date(createdAt);
  const updatedDate = updatedAt ? new Date(updatedAt) : null;


  console.log(lastLoginDate, createdDate, updatedDate);

  if (createdDate > lastLoginDate) {
    return (
      <Tooltip title="New since your last login">
        <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center', ml: 1 }}>
          <NewReleasesIcon sx={{ color: 'success.main', fontSize: '1.2rem' }} />
        </Box>
      </Tooltip>
    );
  }

  if (updatedDate && updatedDate > lastLoginDate) {
    return (
      <Tooltip title="Updated since your last login">
        <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center', ml: 1 }}>
          <ChangeCircleIcon sx={{ color: 'warning.main', fontSize: '1.2rem' }} />
        </Box>
      </Tooltip>
    );
  }

  return null;
}; 