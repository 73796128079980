import React, { useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Alert,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Box,
  IconButton,
  Chip,
  Snackbar,
  Tooltip,
} from '@mui/material';
import { backend } from '../components/user.jsx';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import EnlargedImageModal from '../components/EnlargedImageModal.jsx';
import { IntegrationColor, LastLogin } from '../components/user.jsx';
import MuiAlert from '@mui/material/Alert';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from '../utils/theme.jsx';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import LinkIcon from '@mui/icons-material/Link';
import { teal } from '@mui/material/colors';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import CircleIcon from '@mui/icons-material/Circle';
import CancelIcon from '@mui/icons-material/Cancel';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import DownloadIcon from '@mui/icons-material/Download';

const dateTimeFormatOptions = {
  dateTime: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  },
  dateOnly: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  },
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    padding: theme.spacing(2),
    borderRadius: 16,
    maxWidth: 600,
    background: `linear-gradient(to bottom, ${theme.palette.background.paper} 0%, ${theme.palette.grey[50]} 100%)`,
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  textAlign: 'center',
  fontSize: '1.75rem',
  fontWeight: 600,
  color: theme.palette.primary.main,
  paddingBottom: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
  marginBottom: theme.spacing(3),
}));

const ChoiceSection = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.spacing(2),
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
}));

const EventCard = styled(Card)(({ theme }) => ({
  height: '100%',
  boxShadow: theme.shadows[3],
  '&:hover': {
    boxShadow: theme.shadows[6],
    transition: 'box-shadow 0.3s ease-in-out',
  },
  borderRadius: theme.spacing(2),
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
}));

const EventImage = styled(Box)(({ theme }) => ({
  width: '100%',
  height: { xs: '250px', sm: '300px', md: '400px' },
  objectFit: 'cover',
  cursor: 'pointer',
  borderRadius: theme.spacing(1),
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.02)',
  },
}));

const StatusBar = styled(Box)(({ theme, rsvpStatus, cancelled }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1, 2),
  borderTopLeftRadius: theme.spacing(2),
  borderTopRightRadius: theme.spacing(2),
  marginBottom: 0,
  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  backgroundColor: 
    rsvpStatus === 'cancelled'
      ? theme.palette.grey[600]
      : rsvpStatus === 'attending'
        ? 'rgba(76, 175, 80, 0.2)'
        : rsvpStatus === 'not-attending'
          ? 'rgba(255, 0, 0, 0.1)'
          : rsvpStatus === 'registration-expired'
            ? theme.palette.grey[200]
            : rsvpStatus === 'no-rsvp-required'
              ? theme.palette.grey[100]
              : theme.palette.grey[300],
  transition: 'background-color 0.3s ease',
}));

const TimelineWrapper = styled(Box)(({ theme }) => ({
  position: { xs: 'relative', md: 'sticky' },
  top: theme.spacing(2),
  maxHeight: { xs: '300px', md: 'calc(100vh - 32px)' },
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey[300],
    borderRadius: '3px',
  },
  '& .MuiTimelineItem-root:before': {
    flex: 0,
    padding: 0,
  },
}));

const TimelineEventItem = styled(Box)(({ theme, selected }) => ({
  padding: theme.spacing(1.5),
  cursor: 'pointer',
  borderRadius: theme.spacing(1),
  marginBottom: theme.spacing(0.5),
  backgroundColor: selected ? teal[100] : 'transparent',
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: selected ? teal[200] : theme.palette.action.hover,
  },
  '& .MuiTypography-root': {
    fontWeight: selected ? 600 : 400,
  },
}));

const groupEventsByTimePeriod = (events) => {
  return {
    all: [...events].sort((a, b) => new Date(a.eventStart) - new Date(b.eventStart))
  };
};

const DashedInfoBox = ({ title, children, sx }) => (
  <Box
    sx={{
      flex: '1 1 0',
      minWidth: '150px',
      p: 1,
      border: '1px dashed rgba(0, 0, 0, 0.23)',
      borderRadius: 1,
      position: 'relative',
      mt: 1,
      ...sx,
    }}
  >
    <Typography
      sx={{
        position: 'absolute',
        top: -10,
        left: '50%',
        transform: 'translateX(-50%)',
        bgcolor: 'background.paper',
        px: 1,
        fontSize: '0.875rem',
        color: 'text.secondary',
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
      }}
    >
      {title}
    </Typography>
    <Typography
      variant="body1"
      component="div"
      sx={{
        pt: 1,
        textAlign: title === 'Description' ? 'left' : 'center',
      }}
    >
      {children}
    </Typography>
  </Box>
);

const getEventStatus = (event) => {
  if (event.cancelled) {
    return 'cancelled';
  }
  
  if (!event.rsvp || !event.rsvp.registrationEnd) {
    return 'no-rsvp-required';
  }

  if (event.rsvpResponse?.attending === true) {
    return 'attending';
  }

  if (event.rsvpResponse?.attending === false) {
    return 'not-attending';
  }

  if (new Date() > new Date(event.rsvp.registrationEnd)) {
    return 'registration-expired';
  }

  return 'no-response';
};

const getTimelineIcon = (status, event) => {
  if (status === 'cancelled') {
    return <CancelIcon />;
  }

  if (status === 'no-response') {
    return <QuestionMarkIcon />;
  }

  if (event.updatedAt && 
    new Date(event.updatedAt) > Math.max(
      event.rsvpResponse?.timestamp ? new Date(event.rsvpResponse.timestamp) : 0,
      LastLogin()
    )) {
    return <ChangeCircleIcon />;
  }

  return <CircleIcon />;
};

const getTimelineIconColor = (status, event) => {
  // Check if event is new (created after user's last login)
  const isNew = new Date(event.createdAt) > LastLogin();
  // Check if event was updated (after user's last login, but not newly created)
  const isUpdated = event.updatedAt && 
    new Date(event.updatedAt) > LastLogin() && 
    !isNew;

  if (status === 'cancelled') {
    return 'grey.600';
  }

  if (status === 'attending') {
    return '#2e7d32';
  }

  if (status === 'not-attending') {
    return '#d32f2f';
  }

  return 'grey.400';
};

const getTimelineTooltip = (status, event) => {
  if (status === 'cancelled') {
    return 'Event Cancelled';
  }

  if (event.updatedAt && 
    new Date(event.updatedAt) > LastLogin()) {
    return 'Event Updated since your last login';
  }

  switch (status) {
    case 'attending':
      return 'You are attending';
    case 'not-attending':
      return 'You are not attending';
    case 'registration-expired':
      return 'Registration closed';
    case 'no-rsvp-required':
      return 'No RSVP required';
    case 'no-response':
      return 'RSVP pending';
    default:
      return '';
  }
};

const getRelativeDate = (date) => {
  const now = new Date();
  const eventDate = new Date(date);
  const isOngoing = new Date(date) <= now;
  
  // Check if event is ongoing
  if (isOngoing) return 'Ongoing';
  
  // Calculate days difference
  const diffTime = Math.abs(eventDate - now);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  
  // Check if it's today or tomorrow
  if (eventDate.toDateString() === now.toDateString()) return 'Today';
  if (eventDate.toDateString() === new Date(now.setDate(now.getDate() + 1)).toDateString()) return 'Tomorrow';
  
  // Check if it's within the next 7 days
  if (diffDays <= 7) {
    const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return `On ${weekdays[eventDate.getDay()]}`;
  }
  
  // Check if it's next week
  const nextWeek = new Date(now);
  nextWeek.setDate(now.getDate() + 7);
  if (eventDate <= nextWeek) return 'Next week';
  
  // Check if it's this month or next month
  const thisMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
  const nextMonth = new Date(now.getFullYear(), now.getMonth() + 2, 0);
  
  if (eventDate <= thisMonth) return 'This month';
  if (eventDate <= nextMonth) return 'Next month';
  
  return 'Future';
};

const createICSContent = (event) => {
  const formatDate = (date) => {
    return date.replace(/[-:]/g, '').replace(/\.\d{3}/, '');
  };

  const escapeText = (text) => {
    return text.replace(/[,;\\]/g, '\\$&').replace(/\n/g, '\\n');
  };

  const start = formatDate(event.eventStart);
  const end = formatDate(event.eventEnd);
  
  return [
    'BEGIN:VCALENDAR',
    'VERSION:2.0',
    'PRODID:-//hacksw/handcal//NONSGML v1.0//EN',
    'BEGIN:VEVENT',
    `UID:${event.id}@yourdomain.com`,
    `DTSTAMP:${formatDate(new Date().toISOString())}`,
    `DTSTART:${start}`,
    `DTEND:${end}`,
    `SUMMARY:${escapeText(event.title)}`,
    `DESCRIPTION:${escapeText(event.description)}`,
    `LOCATION:${escapeText(event.location)}`,
    'END:VEVENT',
    'END:VCALENDAR'
  ].join('\r\n');
};

const downloadICS = (event) => {
  const icsContent = createICSContent(event);
  const blob = new Blob([icsContent], { type: 'text/calendar;charset=utf-8' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.setAttribute('download', `${event.title.replace(/[^a-z0-9]/gi, '_').toLowerCase()}.ics`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export function Events() {
  const { events: eventsData, initialEventIndex } = useLoaderData() || { events: [], initialEventIndex: -1 };
  const events = Array.isArray(eventsData) ? eventsData : [];
  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedEvent, setSelectedEvent] = React.useState(null);
  const [attendance, setAttendance] = React.useState('no');
  const [additionalAttendees, setAdditionalAttendees] = React.useState(['']);
  const [currentImageIndexes, setCurrentImageIndexes] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [currentEventIndex, setCurrentEventIndex] = useState(events.length > 0 ? initialEventIndex || 0 : -1);
  const [rsvpChoice, setRsvpChoice] = useState({});
  const [rsvpMessage, setRsvpMessage] = useState('');

  if (events.length === 0) {
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth="xl" sx={{ py: 6 }}>
          <Alert severity="info" sx={{ mt: 4 }}>
            No events found.
          </Alert>
        </Container>
      </ThemeProvider>
    );
  }

  const handleOpenRSVP = (event) => {
    setSelectedEvent(event);
    setOpenDialog(true);
    setAttendance(event.rsvpResponse?.attending ? 'yes' : 'no');
    setAdditionalAttendees(event.rsvpResponse?.additionalAttendees?.length > 0 ? event.rsvpResponse.additionalAttendees : ['']);
    const initialChoices = {};
    if (event.rsvp.choices) {
      event.rsvp.choices.forEach((choice) => {
        const existingChoice = event.rsvpResponse?.choices?.find((c) => c.name === choice.name);
        initialChoices[choice.name] = existingChoice?.value || choice.values[0];
      });
    }
    setRsvpChoice(initialChoices);
    setRsvpMessage(event.rsvpResponse?.message || '');
  };

  const handleClose = () => {
    setOpenDialog(false);
    setSelectedEvent(null);
    setAttendance('no');
    setAdditionalAttendees(['']);
  };

  const handleAttendeeChange = (index, value) => {
    const newAttendees = [...additionalAttendees];
    newAttendees[index] = value;
    setAdditionalAttendees(newAttendees);
  };

  const handleAddAttendee = () => {
    const maxAttendees = selectedEvent?.rsvp?.maxAdditionalAttendees ?? 0;
    if (additionalAttendees.length < maxAttendees) {
      setAdditionalAttendees([...additionalAttendees, '']);
    }
  };

  const handleSubmitRSVP = async () => {
    try {
      const response = await backend.post(`/events/${selectedEvent.id}/rsvp`, {
        attending: attendance === 'yes',
        additionalAttendees: attendance === 'yes' ? additionalAttendees.filter((title) => title.trim()) : [],
        choices:
          attendance === 'yes' && selectedEvent.rsvp.choices
            ? selectedEvent.rsvp.choices.map((choice) => ({
                name: choice.name,
                value: rsvpChoice[choice.name],
              }))
            : [],
        ...(selectedEvent.rsvp?.allowMessage && { message: rsvpMessage.trim() || null }),
      });
      if (response.status === 201) {
        setSnackbar({
          open: true,
          message: 'RSVP submitted successfully!',
          severity: 'success',
        });
        handleClose();
        window.location.reload();
      }
    } catch (error) {
      console.error('Failed to RSVP:', error);
      setSnackbar({
        open: true,
        message: 'Failed to submit RSVP. Please try again.',
        severity: 'error',
      });
    }
  };

  const isWithinRegistrationPeriod = (event) => {
    console.log(event);
    if (!event.rsvp || !event.rsvp.registrationEnd || event.rsvp.registrationEnd === null || event.cancelled ) {
      return false;
    }

    const now = new Date();
    const registrationEnd = new Date(event.rsvp.registrationEnd);
    return now <= registrationEnd;
  };

  const handleImageClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const nextImage = (eventId) => {
    setCurrentImageIndexes((prev) => {
      const currentIndex = prev[eventId] || 0;
      const event = events.find((e) => e.id === eventId);
      return {
        ...prev,
        [eventId]: currentIndex === event.images.length - 1 ? 0 : currentIndex + 1,
      };
    });
  };

  const prevImage = (eventId) => {
    setCurrentImageIndexes((prev) => {
      const currentIndex = prev[eventId] || 0;
      const event = events.find((e) => e.id === eventId);
      return {
        ...prev,
        [eventId]: currentIndex === 0 ? event.images.length - 1 : currentIndex - 1,
      };
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const handleCopyLink = (eventId) => {
    const url = `${window.location.origin}/events/${eventId}`;
    navigator.clipboard.writeText(url).then(() => {
      setSnackbar({
        open: true,
        message: 'Link copied to clipboard!',
        severity: 'success',
      });
    }).catch(() => {
      setSnackbar({
        open: true,
        message: 'Failed to copy link',
        severity: 'error',
      });
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xl" sx={{ py: { xs: 2, md: 6 } }}>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          {/* Timeline Column */}
          <Grid item xs={12} md={3}>
            <TimelineWrapper>
              <Timeline>
                {Object.entries(groupEventsByTimePeriod(events)).map(
                  ([period, periodEvents]) =>
                    periodEvents.length > 0 && (
                      <React.Fragment key={period}>
                        {periodEvents.map((event, index) => (
                          <TimelineItem key={event.id}>
                            <TimelineOppositeContent sx={{ flex: 0.5 }}>
                              <Typography variant="caption" color="text.secondary">
                                {new Date(event.eventStart).toLocaleDateString('sv-SE', {
                                  month: 'short',
                                  day: 'numeric',
                                })}
                              </Typography>
                              <Typography 
                                variant="caption" 
                                color="text.secondary" 
                                sx={{ 
                                  display: 'block',
                                  fontStyle: 'italic',
                                  fontSize: '0.7rem'
                                }}
                              >
                                {getRelativeDate(event.eventStart)}
                              </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                              <TimelineDot sx={{ 
                                p: 0, 
                                bgcolor: 'transparent',
                                boxShadow: 'none',
                                '& .MuiSvgIcon-root': {
                                  fontSize: '1.5rem',
                                  color: getTimelineIconColor(getEventStatus(event), event)
                                }
                              }}>
                                <Tooltip title={getTimelineTooltip(getEventStatus(event), event)} arrow>
                                  <Box component="span">
                                    {getTimelineIcon(getEventStatus(event), event)}
                                  </Box>
                                </Tooltip>
                              </TimelineDot>
                              {index < periodEvents.length - 1 && <TimelineConnector />}
                            </TimelineSeparator>
                            <TimelineContent>
                              <TimelineEventItem
                                selected={events.indexOf(event) === currentEventIndex}
                                onClick={() => setCurrentEventIndex(events.indexOf(event))}
                              >
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                  <Typography
                                    variant="body2"
                                  >
                                    {event.title}
                                  </Typography>
                                </Box>
                              </TimelineEventItem>
                            </TimelineContent>
                          </TimelineItem>
                        ))}
                      </React.Fragment>
                    )
                )}
              </Timeline>
            </TimelineWrapper>
          </Grid>

          {/* Main Content Column */}
          <Grid item xs={12} md={9}>
            {events && events.length > 0 ? (
              <Box sx={{ position: 'relative' }}>
                <Box sx={{ mb: { xs: 2, md: 4 } }}>
                  <StatusBar
                    rsvpStatus={getEventStatus(events[currentEventIndex])}
                    cancelled={events[currentEventIndex].cancelled ? 'true' : 'false'}
                    sx={{ 
                      flexDirection: { xs: 'column', sm: 'row' },
                      gap: { xs: 1, sm: 0 },
                      '& .MuiTypography-h6': {
                        fontSize: { xs: '1rem', sm: '1.25rem' },
                        textAlign: 'center',
                        width: { xs: '100%', sm: 'auto' },
                      },
                      '& .MuiButton-root': {
                        width: { xs: '100%', sm: 'auto' },
                      },
                      '& .MuiBox-root': {
                        width: { xs: '100%', sm: 'auto' },
                        justifyContent: { xs: 'center', sm: 'flex-end' },
                      }
                    }}
                  >
                    <Box>
                      {isWithinRegistrationPeriod(events[currentEventIndex]) && (
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            sx={{
                              px: 4,
                              py: 1,
                              borderRadius: 2,
                            }}
                            onClick={() => handleOpenRSVP(events[currentEventIndex])}
                            disabled={
                              !events[currentEventIndex].rsvpResponse?.attending &&
                              events[currentEventIndex].rsvp.maxAttendees !== 0 &&
                              events[currentEventIndex].currentAttendees >= events[currentEventIndex].rsvp.maxAttendees
                            }
                          >
                            {events[currentEventIndex].rsvpResponse?.attending !== undefined ? 'Update RSVP' : 'RSVP'}
                          </Button>
                        )}
                    </Box>
                    <Typography
                      variant="h6"
                      sx={{
                        flex: 1,
                        textAlign: 'center',
                        color: 'black',
                        fontWeight: 500,
                      }}
                    >
                      {events[currentEventIndex].title}
                      {events[currentEventIndex].cancelled && (
                        <Typography
                          component="span"
                          sx={{
                            color: 'error.main',
                            ml: 2,
                            fontWeight: 'bold',
                          }}
                        >
                          (CANCELLED)
                        </Typography>
                      )}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <IconButton
                        onClick={() => handleCopyLink(events[currentEventIndex].id)}
                        size="small"
                        sx={{
                          bgcolor: 'rgba(0, 0, 0, 0.04)',
                          '&:hover': {
                            bgcolor: 'rgba(0, 0, 0, 0.08)',
                          },
                        }}
                        title="Copy link to event"
                      >
                        <LinkIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        onClick={() => downloadICS(events[currentEventIndex])}
                        size="small"
                        sx={{
                          bgcolor: 'rgba(0, 0, 0, 0.04)',
                          '&:hover': {
                            bgcolor: 'rgba(0, 0, 0, 0.08)',
                          },
                        }}
                        title="Download calendar event"
                      >
                        <DownloadIcon fontSize="small" />
                      </IconButton>
                      {events[currentEventIndex].integrations ? (
                        events[currentEventIndex].integrations.map((integration, index) => (
                          <Chip
                            key={index}
                            label={integration}
                            size="small"
                            sx={{
                              backgroundColor: IntegrationColor(integration),
                              color: 'white',
                              fontWeight: 500,
                            }}
                          />
                        ))
                      ) : (
                        <Chip
                          label="group"
                          size="small"
                          sx={{
                            backgroundColor: IntegrationColor('group'),
                            color: 'white',
                            fontWeight: 500,
                          }}
                        />
                      )}
                    </Box>
                  </StatusBar>
                  <EventCard>
                    <CardContent sx={{ p: { xs: 2, md: 4 }, pt: { xs: 1, md: 2 } }}>
                      <Grid container spacing={{ xs: 2, md: 3 }}>
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'flex-start',
                              mb: 1,
                            }}
                          ></Box>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          md={events[currentEventIndex].images && events[currentEventIndex].images.length > 0 ? 5 : 12}
                          order={{ xs: 2, md: 1 }}
                        >
                          <Box
                            sx={{
                              height: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 2,
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: { xs: 1, md: 2 },
                                '& > *': {
                                  flex: { xs: '1 1 calc(50% - 8px)', md: '1 1 0' },
                                  minWidth: { xs: 'calc(50% - 8px)', md: '150px' },
                                },
                              }}
                            >
                              {/* Event Date Box */}
                              <DashedInfoBox title="Event Date" sx={{ mt: 1.5 }}>
                                {new Date() < new Date(events[currentEventIndex].eventStart) && (
                                  <>
                                    {(() => {
                                      const timeUntilStart =
                                        new Date(events[currentEventIndex].eventStart) - new Date();
                                      const daysUntil = Math.floor(timeUntilStart / (1000 * 60 * 60 * 24));
                                      if (daysUntil > 0) {
                                        return `Starts in ${daysUntil} days`;
                                      } else {
                                        const hoursUntil = Math.floor(timeUntilStart / (1000 * 60 * 60));
                                        const minutesUntil = Math.floor(
                                          (timeUntilStart % (1000 * 60 * 60)) / (1000 * 60)
                                        );
                                        return `Starts in ${hoursUntil}h ${minutesUntil}m`;
                                      }
                                    })()}
                                    <br />
                                  </>
                                )}
                                {new Date(events[currentEventIndex].eventStart).toLocaleDateString('sv-SE') ===
                                new Date(events[currentEventIndex].eventEnd).toLocaleDateString('sv-SE') ? (
                                  <>
                                    {new Date(events[currentEventIndex].eventStart).toLocaleDateString(
                                      'sv-SE',
                                      dateTimeFormatOptions.dateOnly
                                    )}
                                    <br />
                                    {new Date(events[currentEventIndex].eventStart).toLocaleTimeString('sv-SE', {
                                      hour: '2-digit',
                                      minute: '2-digit',
                                    })}{' '}
                                    -
                                    {new Date(events[currentEventIndex].eventEnd).toLocaleTimeString('sv-SE', {
                                      hour: '2-digit',
                                      minute: '2-digit',
                                    })}
                                  </>
                                ) : (
                                  <>
                                    {new Date(events[currentEventIndex].eventStart).toLocaleDateString(
                                      'sv-SE',
                                      dateTimeFormatOptions.dateOnly
                                    )}{' '}
                                    {new Date(events[currentEventIndex].eventStart).toLocaleTimeString('sv-SE', {
                                      hour: '2-digit',
                                      minute: '2-digit',
                                    })}
                                    {' - '}
                                    <br />
                                    {new Date(events[currentEventIndex].eventEnd).toLocaleDateString(
                                      'sv-SE',
                                      dateTimeFormatOptions.dateOnly
                                    )}{' '}
                                    {new Date(events[currentEventIndex].eventEnd).toLocaleTimeString('sv-SE', {
                                      hour: '2-digit',
                                      minute: '2-digit',
                                    })}
                                  </>
                                )}
                              </DashedInfoBox>

                              {/* Location Box */}
                              <DashedInfoBox title="Location">{events[currentEventIndex].location}</DashedInfoBox>

                              {/* Registration Period Box */}
                              {events[currentEventIndex].rsvp?.registrationEnd && (
                                <DashedInfoBox title="Registration Period">
                                  {new Date() > new Date(events[currentEventIndex].rsvp.registrationEnd) ? (
                                    'Registration Closed'
                                  ) : (
                                    <>
                                      {(() => {
                                        const timeUntilClose =
                                          new Date(events[currentEventIndex].rsvp.registrationEnd) - new Date();
                                        const daysUntil = Math.floor(timeUntilClose / (1000 * 60 * 60 * 24));
                                        if (daysUntil > 0) {
                                          return `Closes in ${daysUntil} days`;
                                        } else {
                                          const hoursUntil = Math.floor(timeUntilClose / (1000 * 60 * 60));
                                          const minutesUntil = Math.floor(
                                            (timeUntilClose % (1000 * 60 * 60)) / (1000 * 60)
                                          );
                                          return `Closes in ${hoursUntil}h ${minutesUntil}m`;
                                        }
                                      })()}
                                      <br />
                                      {new Date(events[currentEventIndex].rsvp.registrationEnd).toLocaleDateString(
                                        'sv-SE',
                                        dateTimeFormatOptions.dateOnly
                                      )}{' '}
                                      {new Date(events[currentEventIndex].rsvp.registrationEnd).toLocaleTimeString('sv-SE', {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                      })}
                                    </>
                                  )}
                                </DashedInfoBox>
                              )}

                              {/* RSVP Status Box */}
                              <DashedInfoBox title="RSVP">
                                <Typography variant="body1" component="div">
                                  {events[currentEventIndex].rsvpResponse?.attending !== undefined ? (
                                    <>
                                      <span
                                        style={{
                                          color: events[currentEventIndex].rsvpResponse.attending ? '#2e7d32' : '#d32f2f',
                                        }}
                                      >
                                        {events[currentEventIndex].rsvpResponse.attending ? 'Attending' : 'Not Attending'}
                                      </span>
                                      {events[currentEventIndex].rsvpResponse.rsvpTimestamp && (
                                        <>
                                          <br />
                                          <strong>Response submitted:</strong>{' '}
                                          {new Date(events[currentEventIndex].rsvpResponse.rsvpTimestamp).toLocaleDateString(
                                            'sv-SE',
                                            dateTimeFormatOptions.dateTime
                                          )}
                                        </>
                                      )}
                                      {events[currentEventIndex].rsvpResponse.attending && (
                                        <>
                                          {events[currentEventIndex].rsvpResponse.additionalAttendees?.length > 0 && (
                                            <>
                                              <br />
                                              <strong>Additional Attendees:</strong>
                                              <ul style={{ margin: '4px 0', paddingLeft: '20px' }}>
                                                {events[currentEventIndex].rsvpResponse.additionalAttendees.map(
                                                  (attendee, index) => (
                                                    <li key={index}>{attendee}</li>
                                                  )
                                                )}
                                              </ul>
                                            </>
                                          )}
                                          {events[currentEventIndex].rsvpResponse.choices?.map((choice, index) => (
                                            <React.Fragment key={index}>
                                              <br />
                                              <strong>{choice.name}:</strong> {choice.value}
                                            </React.Fragment>
                                          ))}
                                          {events[currentEventIndex].rsvpResponse?.message && (
                                            <>
                                              <br />
                                              <strong>Message:</strong> {events[currentEventIndex].rsvpResponse.message}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </>
                                  ) : events[currentEventIndex].rsvp?.registrationEnd ? (
                                    new Date() > new Date(events[currentEventIndex].rsvp.registrationEnd) ? (
                                      'Registration Expired'
                                    ) : (
                                      'No response'
                                    )
                                  ) : (
                                    'No RSVP required'
                                  )}
                                </Typography>
                              </DashedInfoBox>

                              {/* Capacity Box - Only show if maxAttendees is not 0 */}
                              {events[currentEventIndex].rsvp.maxAttendees !== 0 && (
                                <DashedInfoBox title="Capacity">
                                  {events[currentEventIndex].rsvp.maxAttendees - events[currentEventIndex].currentAttendees}{' '}
                                  slots remaining
                                  {events[currentEventIndex].currentAttendees >=
                                    events[currentEventIndex].rsvp.maxAttendees && (
                                    <Typography color="error.main" sx={{ mt: 1 }}>
                                      Event is at full capacity
                                    </Typography>
                                  )}
                                </DashedInfoBox>
                              )}
                            </Box>
                          </Box>
                        </Grid>

                        {events[currentEventIndex].images && events[currentEventIndex].images.length > 0 ? (
                          <>
                            <Grid item xs={12} md={7} order={{ xs: 1, md: 2 }}>
                              <Box sx={{ position: 'relative' }}>
                                <EventImage
                                  component="img"
                                  src={
                                    events[currentEventIndex].images[
                                      currentImageIndexes[events[currentEventIndex].id] || 0
                                    ].url
                                  }
                                  alt={
                                    events[currentEventIndex].images[
                                      currentImageIndexes[events[currentEventIndex].id] || 0
                                    ].name
                                  }
                                  onClick={() => handleImageClick()}
                                />
                                {events[currentEventIndex].images.length > 1 && (
                                  <>
                                    <IconButton
                                      sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: 8,
                                        transform: 'translateY(-50%)',
                                        bgcolor: 'rgba(255,255,255,0.7)',
                                        '&:hover': {
                                          bgcolor: 'rgba(255,255,255,0.9)',
                                        },
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        prevImage(events[currentEventIndex].id);
                                      }}
                                    >
                                      <ArrowBackIosNewIcon />
                                    </IconButton>
                                    <IconButton
                                      sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        right: 8,
                                        transform: 'translateY(-50%)',
                                        bgcolor: 'rgba(255,255,255,0.7)',
                                        '&:hover': {
                                          bgcolor: 'rgba(255,255,255,0.9)',
                                        },
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        nextImage(events[currentEventIndex].id);
                                      }}
                                    >
                                      <ArrowForwardIosIcon />
                                    </IconButton>
                                  </>
                                )}
                              </Box>
                            </Grid>
                            <Grid item xs={12} order={3}>
                              <DashedInfoBox title="Description">
                                {events[currentEventIndex].description}
                              </DashedInfoBox>
                            </Grid>

                            {/* Add metadata section */}
                            <Grid item xs={12} order={4}>
                              <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                                <Typography variant="caption" color="text.secondary" sx={{ textAlign: 'right' }}>
                                  Created by {events[currentEventIndex].createdBy} on{' '}
                                  {new Date(events[currentEventIndex].createdAt).toLocaleDateString('sv-SE', dateTimeFormatOptions.dateTime)}
                                  {events[currentEventIndex].updatedAt && events[currentEventIndex].updatedBy && (
                                    <>
                                      <br />
                                      Last updated by {events[currentEventIndex].updatedBy} on{' '}
                                      {new Date(events[currentEventIndex].updatedAt).toLocaleDateString('sv-SE', dateTimeFormatOptions.dateTime)}
                                    </>
                                  )}
                                </Typography>
                              </Box>
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid item xs={12} order={3}>
                              <DashedInfoBox title="Description">
                                {events[currentEventIndex].description}
                              </DashedInfoBox>
                            </Grid>

                            {/* Add metadata section here too */}
                            <Grid item xs={12} order={4}>
                              <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                                <Typography variant="caption" color="text.secondary" sx={{ textAlign: 'right' }}>
                                  Created by {events[currentEventIndex].createdBy} on{' '}
                                  {new Date(events[currentEventIndex].createdAt).toLocaleDateString('sv-SE', dateTimeFormatOptions.dateTime)}
                                  {events[currentEventIndex].updatedAt && events[currentEventIndex].updatedBy && (
                                    <>
                                      <br />
                                      Last updated by {events[currentEventIndex].updatedBy} on{' '}
                                      {new Date(events[currentEventIndex].updatedAt).toLocaleDateString('sv-SE', dateTimeFormatOptions.dateTime)}
                                    </>
                                  )}
                                </Typography>
                              </Box>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </CardContent>
                  </EventCard>
                </Box>
              </Box>
            ) : (
              <Alert severity="info" sx={{ mt: 4 }}>
                No events found.
              </Alert>
            )}
          </Grid>
        </Grid>
        <StyledDialog 
          open={openDialog} 
          onClose={handleClose} 
          fullWidth
          maxWidth="sm"
          sx={{
            '& .MuiDialog-paper': {
              margin: { xs: 2, sm: 4 },
              width: { xs: 'calc(100% - 32px)', sm: '600px' },
            }
          }}
        >
          <StyledDialogTitle>{selectedEvent?.title} - RSVP</StyledDialogTitle>
          <DialogContent sx={{ pb: 4 }}>
            <Box sx={{ mb: 4, textAlign: 'center' }}>
              <Typography variant="h6" color="primary" sx={{ mb: 1 }}>
                Will you be attending this event?
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {new Date(selectedEvent?.eventStart).toLocaleDateString('sv-SE', dateTimeFormatOptions.dateTime)}
              </Typography>
            </Box>

            <ChoiceSection>
              <RadioGroup 
                value={attendance} 
                onChange={(e) => setAttendance(e.target.value)}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  gap: 4
                }}
              >
                <FormControlLabel
                  value="yes"
                  control={
                    <Radio 
                      sx={{
                        '&.Mui-checked': {
                          color: 'success.main',
                        }
                      }}
                    />
                  }
                  label={
                    <Box sx={{ textAlign: 'center' }}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'success.main' }}>
                        Yes, I will attend
                      </Typography>
                    </Box>
                  }
                />
                <FormControlLabel
                  value="no"
                  control={
                    <Radio 
                      sx={{
                        '&.Mui-checked': {
                          color: 'error.main',
                        }
                      }}
                    />
                  }
                  label={
                    <Box sx={{ textAlign: 'center' }}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'error.main' }}>
                        No, I cannot attend
                      </Typography>
                    </Box>
                  }
                />
              </RadioGroup>
            </ChoiceSection>

            {attendance === 'yes' && (
              <>
                {(selectedEvent?.rsvp?.maxAdditionalAttendees ?? 0) > 0 && (
                  <ChoiceSection>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                      Additional Attendees
                      <Typography component="span" variant="body2" color="text.secondary" sx={{ ml: 1 }}>
                        (Max: {selectedEvent.rsvp.maxAdditionalAttendees})
                      </Typography>
                    </Typography>
                    {additionalAttendees.map((attendee, index) => (
                      <TextField
                        key={index}
                        fullWidth
                        size="small"
                        label={`Attendee ${index + 1}`}
                        value={attendee}
                        onChange={(e) => handleAttendeeChange(index, e.target.value)}
                        sx={{ mb: 2 }}
                        variant="outlined"
                      />
                    ))}
                    {additionalAttendees.length < (selectedEvent?.rsvp?.maxAdditionalAttendees ?? 0) && (
                      <Button 
                        onClick={handleAddAttendee} 
                        startIcon={<AddIcon />} 
                        sx={{ 
                          mt: 1,
                          borderRadius: 2,
                        }}
                        variant="outlined"
                      >
                        Add Another Attendee
                      </Button>
                    )}
                  </ChoiceSection>
                )}

                {selectedEvent?.rsvp?.choices && (
                  <ChoiceSection>
                    {selectedEvent.rsvp.choices.map((choice, index) => (
                      <Box key={index} sx={{ mb: index !== selectedEvent.rsvp.choices.length - 1 ? 4 : 0 }}>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                          {choice.name}
                        </Typography>
                        <RadioGroup
                          value={rsvpChoice[choice.name] || ''}
                          onChange={(e) =>
                            setRsvpChoice((prev) => ({
                              ...prev,
                              [choice.name]: e.target.value,
                            }))
                          }
                          sx={{ ml: 1 }}
                        >
                          {choice.values.map((value, valueIndex) => (
                            <FormControlLabel
                              key={valueIndex}
                              value={value}
                              control={<Radio color="primary" />}
                              label={value}
                            />
                          ))}
                        </RadioGroup>
                      </Box>
                    ))}
                  </ChoiceSection>
                )}

                {selectedEvent?.rsvp?.allowMessage && (
                  <ChoiceSection>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                      Message
                      <Typography component="span" variant="body2" color="text.secondary" sx={{ ml: 1 }}>
                        (Optional)
                      </Typography>
                    </Typography>
                    <TextField
                      fullWidth
                      multiline
                      rows={3}
                      size="small"
                      placeholder="Add a message to the organizers..."
                      value={rsvpMessage}
                      onChange={(e) => setRsvpMessage(e.target.value)}
                      variant="outlined"
                    />
                  </ChoiceSection>
                )}
              </>
            )}
          </DialogContent>
          <DialogActions 
            sx={{ 
              px: { xs: 2, md: 3 }, 
              pb: { xs: 2, md: 3 }, 
              gap: 2,
              flexDirection: { xs: 'column', sm: 'row' },
              '& .MuiButton-root': {
                width: { xs: '100%', sm: 'auto' }
              }
            }}
          >
            <Button 
              onClick={handleClose} 
              variant="outlined" 
              sx={{ 
                borderRadius: 2,
                px: 3,
              }}
            >
              Cancel
            </Button>
            <Button 
              onClick={handleSubmitRSVP} 
              variant="contained" 
              color="primary"
              sx={{ 
                borderRadius: 2,
                px: 4,
              }}
            >
              Submit RSVP
            </Button>
          </DialogActions>
        </StyledDialog>
        <EnlargedImageModal
          open={isModalOpen}
          handleClose={handleCloseModal}
          images={events[currentEventIndex].images}
          initialImageIndex={currentImageIndexes[events[currentEventIndex].id] || 0}
        />
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <MuiAlert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity={snackbar.severity}>
            {snackbar.message}
          </MuiAlert>
        </Snackbar>
      </Container>
    </ThemeProvider>
  );
}

export async function eventsLoader({ params }) {
  try {
    const response = await backend.get('/events/events');

    if (response.status !== 200) {
      throw new Response('Failed to fetch shop articles', {
        status: response.status,
        statusText: response.statusText,
      });
    }

    const events = response.data;
    
    // If there's an event ID in the URL, set it as the current event
    if (params.id) {
      const eventIndex = events.findIndex(event => event.id === parseInt(params.id, 10));
      if (eventIndex !== -1) {
        return { events, initialEventIndex: eventIndex };
      }
    }

    // Find the event with the most recent start date
    const now = new Date();
    const mostRecentEventIndex = events.reduce((closestIdx, event, currentIdx, arr) => {
      if (closestIdx === -1) return currentIdx;
      
      const eventDate = new Date(event.eventStart);
      const closestDate = new Date(arr[closestIdx].eventStart);
      const eventDiff = Math.abs(eventDate - now);
      const closestDiff = Math.abs(closestDate - now);
      
      return eventDiff < closestDiff ? currentIdx : closestIdx;
    }, -1);

    return { events, initialEventIndex: mostRecentEventIndex };
  } catch (err) {
    if (err?.status) {
      throw new Response('', { status: err.status });
    }
    throw err;
  }
}
