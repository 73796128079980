import React, { useState } from 'react';
import { useNavigate, useLoaderData } from 'react-router-dom';
import {
  Container,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Avatar,
  AvatarGroup,
  Tooltip,
  Chip,
  Box,
} from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { backend, IntegrationColor } from '../components/user.jsx';
import { format, parseISO } from 'date-fns';

export function ArticleList() {
  const articles_ = useLoaderData();
  const sortedArticles = (articles_ || []).sort((a, b) => b.id - a.id);
  const [articles, setArticles] = useState(sortedArticles);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [articleToDelete, setArticleToDelete] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const navigate = useNavigate();

  const handleEditClick = (articleId) => {
    navigate(`/shop/add-article?id=${articleId}`);
  };

  const handleDeleteClick = (article) => {
    setArticleToDelete(article);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      const response = await backend.delete(`/shop/admin/articles/${articleToDelete.id}`);
      if (response.status === 200) {
        setArticles(articles.filter((article) => article.id !== articleToDelete.id));
        setSnackbarMessage('Article deleted successfully');
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage('Failed to delete article');
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage(
        'Failed to delete article with error: ' +
          (error.response?.data?.message || error.message || 'Failed to delete article')
      );
      setSnackbarOpen(true);
    } finally {
      setDeleteDialogOpen(false);
      setArticleToDelete(null);
    }
  };

  const isArticleActive = (article) => {
    const now = new Date();
    const start = article.activeStart ? new Date(article.activeStart) : null;
    const end = article.activeEnd ? new Date(article.activeEnd) : null;

    if (!start && !end) return true; // Always active if no dates are set
    if (start && !end) return now >= start;
    if (!start && end) return now <= end;
    return now >= start && now <= end;
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    return format(parseISO(dateString), 'yyyy-MM-dd HH:mm');
  };

  const formatAttributes = (attributes) => {
    if (!attributes || attributes.length === 0) return <Chip label="N/A" size="small" />;
    return (
      <>
        {attributes.map(({ name, quantity }, index) => (
          <Chip
            key={index}
            label={`${name}: ${quantity}`}
            size="small"
            style={{
              margin: '2px',
              backgroundColor: '#e0e0e0',
            }}
          />
        ))}
      </>
    );
  };

  const formatDateRange = (start, end) => {
    if (!start && !end) return 'N/A';
    const formatDate = (date) => (date ? format(new Date(date), 'yyyy-MM-dd') : '∞');
    return `${formatDate(start)} to ${formatDate(end)}`;
  };

  return (
    <Container maxWidth={false}>
      <Box sx={{ my: 2, display: 'flex', justifyContent: 'flex-start' }}>
        <Button variant="contained" color="success" onClick={() => navigate('/shop/add-article')} sx={{ mb: 2 }}>
          Create New Article
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>ID</strong>
              </TableCell>
              <TableCell>
                <strong>Name</strong>
              </TableCell>
              <TableCell>
                <strong>Description</strong>
              </TableCell>
              <TableCell>
                <strong>Price</strong>
              </TableCell>
              <TableCell>
                <strong>Collection</strong>
              </TableCell>
              <TableCell>
                <strong>Integrations</strong>
              </TableCell>
              <TableCell>
                <strong>Images</strong>
              </TableCell>
              <TableCell>
                <strong>Attributes</strong>
              </TableCell>
              <TableCell>
                <strong>Active</strong>
              </TableCell>
              <TableCell>
                <strong>Created By</strong>
              </TableCell>
              <TableCell>
                <strong>Created At</strong>
              </TableCell>
              <TableCell>
                <strong>Updated By</strong>
              </TableCell>
              <TableCell>
                <strong>Updated At</strong>
              </TableCell>
              <TableCell>
                <strong>Actions</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {articles && articles.length > 0 ? (
              articles.map((article) => (
                <TableRow
                  key={article.id}
                  style={{
                    backgroundColor: isArticleActive(article) ? '#e8f5e9' : 'inherit',
                  }}
                >
                  <TableCell>{article.id}</TableCell>
                  <TableCell>{article.name}</TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        maxWidth: 200,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {article.description}
                    </Typography>
                  </TableCell>
                  <TableCell>{article.price}</TableCell>
                  <TableCell>{article.collection || 'N/A'}</TableCell>
                  <TableCell>
                    {article.integrations && article.integrations.length > 0 ? (
                      article.integrations.map((integration, index) => (
                        <Chip
                          key={index}
                          label={integration}
                          size="small"
                          style={{
                            backgroundColor: IntegrationColor(integration),
                            margin: '2px',
                            color: 'white',
                          }}
                        />
                      ))
                    ) : (
                      <Chip
                        label="Group"
                        size="small"
                        style={{
                          backgroundColor: IntegrationColor('group'),
                          color: 'white',
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    <AvatarGroup max={7}>
                      {article.images &&
                        article.images.map((image, index) => (
                          <Avatar key={index} alt={image.name} src={image.url} title={image.name} />
                        ))}
                    </AvatarGroup>
                  </TableCell>
                  <TableCell>{formatAttributes(article.attributes)}</TableCell>
                  <TableCell>
                    <Tooltip title={isArticleActive(article) ? 'Active' : 'Inactive'}>
                      <span>{formatDateRange(article.activeStart, article.activeEnd)}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>{article.createdBy}</TableCell>
                  <TableCell>{formatDate(article.createdAt)}</TableCell>
                  <TableCell>{article.updatedBy || 'N/A'}</TableCell>
                  <TableCell>{article.updatedAt ? formatDate(article.updatedAt) : 'N/A'}</TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => handleEditClick(article.id)}
                      size="small"
                      aria-label="edit"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() => handleDeleteClick(article)}
                      size="small"
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={13} align="center">
                  No articles found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Confirm Delete'}</DialogTitle>
        <DialogContent>Are you sure you want to delete this article?</DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Container>
  );
}

export async function articleListLoader() {
  try {
    const response = await backend.get('/shop/admin/articles/');
    if (response.status !== 200) {
      throw new Response('Failed to fetch articles', {
        status: response.status,
        statusText: response.statusText,
      });
    }
    return response.data;
  } catch (err) {
    if (err?.status) {
      throw new Response('', { status: err.status });
    }
    throw err;
  }
}
